import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css'
import { UserAuth } from '../../../../Contexts/AuthContext'
import { Link, useMatch, useResolvedPath, useLocation } from 'react-router-dom'
import { UserTheme } from '../../../../Contexts/ThemeContext'
import { useSearch } from './SearchProvider'
import { useSearch2 } from './SearchProvider2'
import { useSearch3 } from './SearchProvider3'

function Navbar() {
  //Descructured variables
  const { theme, navLogo } = UserTheme()
  const { search, setSearch } = useSearch()
  const { search2, setSearch2 } = useSearch2()
  const { search3, setSearch3 } = useSearch3()
  const [placeholderText, setPlaceholderText] = useState('');
  const [placeholderText2, setPlaceholderText2] = useState('');
  const [placeholderText3, setPlaceholderText3] = useState('');
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState('false')

  const Location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchChange = (e, placeholder) => {
    setSearch(e.target.value);
    setPlaceholderText(placeholder);
  };

  const handleSearchChange2 = (e2, placeholder2) => {
    setSearch2(e2.target.value);
    setPlaceholderText2(placeholder2);
  };

  const handleSearchChange3 = (e3, placeholder3) => {
    setSearch3(e3.target.value);
    setPlaceholderText3(placeholder3);
  };

  return (
    <div className={`${styles.Navbar} ${scrolled ? styles.scrolled : ''}`} data-theme={theme}>
      <a href="/feed">
        <img
          className={styles.Nav__Logo}
          src={navLogo}
          alt="logo"
          height="60"
          width="60"
        />
      </a>
      {Location.pathname === '/search' ? (
        <a>
          <input
            className={styles.Search__Bar}
            id={'search-communities'}
            type={'text'}
            value={search2}
            placeholder={placeholderText2 || 'Search Communities'}
            onChange={(e) => handleSearchChange2(e, 'Search Communities')}
          />
        </a>
      ) : Location.pathname === '/media' ? (
        <a>
          <input
            className={styles.Search__Bar}
            id={'search-media'}
            type={'text'}
            value={search}
            placeholder={placeholderText || 'Search For A Series'}
            onChange={(e) => handleSearchChange(e, 'Search For A Series')}
          />
        </a>
      ) : Location.pathname === '/shop' ? (
        <a>
          <input
            className={styles.Search__Bar}
            id={'search-shop'}
            type={'text'}
            value={search3}
            placeholder={placeholderText3 || 'Search Items'}
            onChange={(e) => handleSearchChange3(e, 'Search Items')}
          />
        </a>
      ) : (
        <div></div>
      )}
      <div
        className={`${styles['Nav__Items']} ${styles[`${isOpen && 'open'}`]}`}
      >
        <CustomLink to="/feed" id={styles.test}>
          <div className={styles.Feed}></div>
        </CustomLink>
        <CustomLink to="/media">
          <div className={styles.Media}></div>
        </CustomLink>
        <CustomLink to="/search">
          <div className={styles.Search}></div>
        </CustomLink>
        <CustomLink to="/create-post">
          {' '}
          <div className={styles.Post}></div>
        </CustomLink>
        <CustomLink to="/shop">
          {' '}
          <div className={styles.Shop}></div>
        </CustomLink>
        <CustomLink to="/profile">
          {' '}
          <div className={styles.Profile}></div>
        </CustomLink>
      </div>
      <div
        className={`${styles['Nav__Toggle']} ${styles[`${isOpen && 'open'}`]}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.Bar}></div>
      </div>
    </div>
  )
}

function CustomLink({ to, children, ...props }) {
  const { setSearch, displaySearch, setDisplaySearch } = UserAuth()
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <Link to={to} {...props} id={isActive ? styles.active : ''}>
      {children}
    </Link>
  )
}
export default Navbar
