import React, { createContext, useContext, useState } from 'react';

const SearchContext2 = createContext();

export function SearchProvider2({ children }) {
  const [search2, setSearch2] = useState('');
  const [placeholderText2, setPlaceholderText2] = useState('');

  return (
    <SearchContext2.Provider value={{ search2, setSearch2, placeholderText2, setPlaceholderText2 }}>
      {children}
    </SearchContext2.Provider>
  );
}

export function useSearch2() {
  return useContext(SearchContext2);
}