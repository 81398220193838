import React, { useState } from 'react'
import styles from './Required.module.css'
import BannerModal from '../../Profile/Modals/BannerModal/BannerModal'
import ProfileModal from '../../Profile/Modals/ProfileModal/ProfileModal'
import { UserAuth } from '../../../../../../../Contexts/AuthContext'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'
import { Modals } from '../../../../../../../Contexts/ModalsContext'

function Required() {
  const { theme } = UserTheme()
  const { user, required, userObj, postBio, postBirthday } = UserAuth()

  const { setIsBannerOpen, setIsProfileOpen } = Modals()

  const [bio, setBio] = useState()
  const [birthday, setBirthday] = useState()

  const defaultProfile =
    'https://firebasestorage.googleapis.com/v0/b/triton-4848e.appspot.com/o/basic-user-image%2Fno-profile-pic.png?alt=media&token=04dfda8a-c833-41ca-aeae-86eee9f04b0f'
  const defaultBanner =
    'https://firebasestorage.googleapis.com/v0/b/triton-4848e.appspot.com/o/basic-banner-image%2FBasicBanner.jpg?alt=media&token=174158af-4995-43a3-9b42-b2f24411979d'
  return (
    <div className={styles.Required__Screen}>
      <div className={styles.Account__Wrapper}>
        <span className={styles.User__Name}>{userObj.username}</span>
        <div className={styles.Profile__Banner}>
          <img
            src={
              !userObj.bannerImageUrl ? defaultBanner : userObj.bannerImageUrl
            }
            className={styles.Banner}
          />
          <button
            className={styles.Open__Banner__Modal}
            onClick={() => {
              setIsBannerOpen(true)
            }}
          ></button>

          <img
            className={styles.Circle__Profile}
            src={
              !userObj.profileImageUrl
                ? defaultProfile
                : userObj.profileImageUrl
            }
          ></img>
          <button
            className={styles.Open__Profile__Modal}
            onClick={() => {
              setIsProfileOpen(true)
            }}
          ></button>
        </div>
        <div className={styles.User__Bio}>
          {userObj.profileDescription ? (
            userObj.profileDescription
          ) : (
            <input
              type="text"
              placeholder="Enter a bio!"
              className={styles.Enter__Bio}
              onChange={(e) => setBio(e.target.value)}
            />
          )}
          {bio ? (
            <button className={styles.Confirm} onClick={() => postBio(bio)}>
              Confirm
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.User__Birthday}>
          {userObj.birthday ? (
            <div>{userObj.birthday}</div>
          ) : (
            <>
              Enter Birthday:
              <div className={styles.Birthday__Stuff}>
                <input
                  type="text"
                  placeholder="month dd, yyyy"
                  className={styles.Enter__Birthday}
                  onChange={(e) => setBirthday(e.target.value)}
                />
              </div>
            </>
          )}
          {birthday && !userObj.birthday ? (
            <button
              className={styles.Confirm}
              onClick={() => postBirthday(birthday)}
            >
              Confirm
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <BannerModal />
      <ProfileModal />
    </div>
  )
}

export default Required
