import React, { useState, useEffect } from 'react'
import styles from './UrlPreview2.module.css'

function UrlPreview2({ url }) {
  const [metadata, setMetadata] = useState(null)

  useEffect(() => {
    const apiUrl = `https://api.linkpreview.net/?key=8a3ec6ae6888baf02bb27369525d0d1a&q=${url}`

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setMetadata(data)
      })
      .catch((error) => {
        // console.log(error)
      })
  }, [url])

  if (!metadata)
    return (
      <div>
        <img src={''} alt="Preview" className={styles.Url} />
      </div>
    )

  return (
    <div>
      <a href={metadata.url} target="_blank" rel="noreferrer">
        <img src={metadata.image} alt="Preview" className={styles.Url} />
      </a>
    </div>
  )
}

export default UrlPreview2
