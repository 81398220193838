import { createContext, useContext, useState } from 'react'

const ModalsContext = createContext()
export const ModalsContextProvider = ({ children }) => {
  /*1*/ const [isEmailVerified, setIsEmailVerified] = useState(false)
  /*2*/ const [isProfileOpen, setIsProfileOpen] = useState(false)
  /*3*/ const [isBannerOpen, setIsBannerOpen] = useState(false)
  /*4*/ const [isPostOpen, setIsPostOpen] = useState(false)
  /*5*/ const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  /*6*/ const [isEditOpen, setIsEditOpen] = useState(false)
  /*7*/ const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  /*8*/ const [isEpisodesOpen, setIsEpisodesOpen] = useState(false)
  /*9*/ const [isSequencesOpen, setIsSequencesOpen] = useState(false)

  return (
    <ModalsContext.Provider
      value={{
        isProfileOpen,
        setIsProfileOpen,
        isBannerOpen,
        setIsBannerOpen,
        isEmailVerified,
        setIsEmailVerified,
        isOptionsOpen,
        setIsOptionsOpen,
        isEditOpen,
        setIsEditOpen,
        isPreviewOpen,
        setIsPreviewOpen,
        isEpisodesOpen,
        setIsEpisodesOpen,
        isSequencesOpen,
        setIsSequencesOpen,
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export const Modals = () => {
  return useContext(ModalsContext)
}
