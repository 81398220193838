import React, { useState, useEffect } from 'react'
import styles from './ForgotPassword.module.css'
import { Link } from 'react-router-dom'
import { UserTheme } from '../../../Contexts/ThemeContext'
import { UserAuth } from '../../../Contexts/AuthContext'

const ForgotPassword = () => {
  const { logo, theme } = UserTheme()
  //useState Hooks
  const [forgotPasswordButton, setForgotPasswordButtion] = useState('disabled')
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [error, setError] = useState('')
  // console.log(error)

  //Descructured variables
  const { passwordReset, hide } = UserAuth()

  //useEffect Hooks
  useEffect(() => {
    if (forgotPasswordEmail !== '') {
      setForgotPasswordButtion('')
    } else {
      setForgotPasswordButtion('disabled')
    }
  }, [forgotPasswordEmail])

  useEffect(() => {
    // Check if the user is visiting on a mobile device
    const isMobileDevice = /(iPhone|iPad|iPod|Android)/i.test(
      navigator.userAgent
    )

    // Redirect to the App Store if it's a mobile device
    if (isMobileDevice) {
      window.location.href =
        'https://apps.apple.com/us/app/rekishi/id1593486431'
    }
  }, [])

  //Password Reset Function
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      await passwordReset(forgotPasswordEmail)
    } catch (e) {
      setError(e.message)
      // console.log(e.message)
    }
    // console.log('An email has been sent for you to reset your password')
  }

  if (hide)
    return (
      <div className={styles.Login__Wrapper} data-theme={theme}>
        <img
          src={logo}
          alt="logo"
          width="300"
          height="138"
          className={styles.Logo}
          data-theme={theme}
        />
      </div>
    )

  return (
    <div className={styles.ForgotPassword__Wrapper} data-theme={theme}>
      <div>
        <img
          src={logo}
          alt="logo"
          width="300"
          height="138"
          className={styles.Logo}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.ForgotPassword__Form__Inner}>
          <div className={styles.ForgotPassword__Form__Group}>
            <label htmlFor="email"></label>
            <input
              className={styles.ForgotPassword__Form__Inputs}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              autoComplete="on"
              required
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
            />
          </div>
          <div className={styles.ForgotPassword__Form__Group}>
            <input
              className={styles.ForgotPassword__Form__Inputs}
              type="submit"
              value="Forgot Password"
              id={styles.ForgotPassword__Submit}
              disabled={forgotPasswordButton}
            />
          </div>
          <p className={styles.ForgotPassword__Form__Group} id={styles.Link}>
            <Link to="/"> Cancel</Link>
          </p>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
