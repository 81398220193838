import React, { useState, useEffect } from 'react';
import styles from './Media.module.css';
import uuid from 'react-uuid';
import MediaItems from './MediaItems/MediaItems';
import { UserTheme } from '../../../../../../Contexts/ThemeContext';
import { UserAuth } from '../../../../../../Contexts/AuthContext';
import { useSearch } from '../../SearchProvider';

function Media() {
  const { theme } = UserTheme();
  const { getMedia, mediaPosts } = UserAuth();
  const { search, setSearch } = useSearch();
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [mediaObj, setMediaObj] = useState([]);
  const [sortedMedia, setSortedMedia] = useState([]);

  useEffect(() => {
    // Define a function to load media data
    const loadMediaData = () => {
      if (!mediaPosts) {
        getMedia();
      }
    };

    // Load data on page load or refresh
    loadMediaData();

    // Load the search text from local storage
    const storedSearch = localStorage.getItem('mediasearch');
    if (storedSearch) {
      setSearch(storedSearch);
    }
  }, [getMedia]);

  useEffect(() => {
    if (mediaPosts) {
      setMediaObj(Object.entries(mediaPosts));
    }
  }, [mediaPosts]);

  useEffect(() => {
    if (mediaObj.length > 0) {
      const mediaArr = mediaObj.map((media) => [
        /*1*/ media[1].Title,
        /*2*/ media[1].About,
        /*3*/ media[1].Author,
        /*4*/ media[1].Banner,
        /*5*/ media[1].Chapters,
        /*6*/ media[1]['Image'],
        /*7*/ media[1].Lanugage,
        /*8*/ media[1].Likes,
        /*9*/ media[1].Manga,
        /*10*/ media[1].MediaID,
        /*11*/ media[1].NSFW,
        /*12*/ media[1].Sequence1,
        /*13*/ media[1].Sequences,
        /*14*/ media[1].Video,
        /*15*/ media[1].Volumes,
        /*16*/ media[1].Views,
        /*17*/ media[1].Episodes,
        /*18*/ media[1].Episodes1,
      ]);

      // Sort the media array
      const sortMedia = (media) => {
        return media.sort();
      };

      setSortedMedia(sortMedia(mediaArr));
    }
  }, [mediaObj]);

  useEffect(() => {
    if (sortedMedia) {
      setFilteredMedia(
        sortedMedia.filter((media) =>
          media[0].toLowerCase().includes(search.toLowerCase())
        )
      );

      // Save the search text to local storage whenever it changes
      localStorage.setItem('mediasearch', search);
    }
  }, [search, sortedMedia]);

  return (
    <div className={styles.Feed__Container} data-theme={theme}>
      <div className={styles.Search__Item}>
        {filteredMedia ? (
          filteredMedia.map((media, index) => (
            <div key={uuid()}>
              <MediaItems media={media} index={index} sortedMedia={sortedMedia} />
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default Media;