import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export function SearchProvider({ children }) {
  const [search, setSearch] = useState('');
  const [placeholderText, setPlaceholderText] = useState('');

  return (
    <SearchContext.Provider value={{ search, setSearch, placeholderText, setPlaceholderText }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  return useContext(SearchContext);
}