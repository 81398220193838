import React, { useEffect, useState } from 'react'
import styles from './YourAccount.module.css'
import { db } from '../../../../../../firebase'
import { ref as dbRef, onValue } from 'firebase/database'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'
import { UserAuth } from '../../../../../../Contexts/AuthContext'

function YourAccount() {
  const { user } = UserAuth()
  const { theme } = UserTheme()

  const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    const getUserData = (ID) => {
      onValue(dbRef(db, 'users/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setCurrentUser(data)
      })
    }

    getUserData(user.uid)
  }, [])

  if (!currentUser) return null

  return (
    <div className={styles.Wrapper} data-theme={theme}>
      <div className={styles.Banner__Container}>
        <img
          className={styles.BannerIMG}
          src={currentUser.bannerImageUrl}
          alt="banner"
        />
      </div>
      <div className={styles.Info__Container}>
        <div className={styles.Info}>
          <img
            className={styles.ProfileIMG}
            src={currentUser.profileImageUrl}
            alt="profile"
          />
          <div className={styles.Name__Bio}>
            <div className={styles.Username}>{'@' + currentUser.username}</div>
            <div className={styles.Description}>
              {currentUser.profileDescription
                ? currentUser.profileDescription
                : 'No Description Yet'}
            </div>
            <div className={styles.URL}>Enter URL</div>
          </div>
        </div>
      </div>
      <div className={styles.Container}>
        <input
          className={styles.Inputs}
          id={styles.Username}
          type="text"
          placeholder="Username:"
        />
        <input className={styles.Inputs} type="email" placeholder="Email:" />
        <input
          className={styles.Inputs}
          type="text"
          placeholder="Phone Number:"
        />
        <input className={styles.Inputs} type="text" placeholder="Birthday:" />
        <input
          className={styles.Inputs}
          type="password"
          placeholder="Password:"
        />
        <button className={styles.Links}>Account Statistics</button>
        <button className={styles.Links}>User Achievments</button>
        <button className={styles.Links} id={styles.delete}>
          Delete Account
        </button>
      </div>
    </div>
  )
}

export default YourAccount
