import React from 'react'
import styles from './LoadingSpinner.module.css'

const LoadingSpinner = () => (
  <div className={styles.Container}>
    <div className={styles.Spinner}></div>
  </div>
)

export default LoadingSpinner
