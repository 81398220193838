import React from 'react'
import styles from './CartModal.module.css'
import ReactDOM from 'react-dom'
import uuid from 'react-uuid'
import CartItem from '../../Authenticate/Account/Navbar/Pages/Shop/ShopItems/Item/Cart/CartItem/CartItem'
import Checkout from '../../Authenticate/Account/Navbar/Pages/Shop/Checkout/Checkout'
import { useNavigate, Link } from 'react-router-dom'
import { UserCart } from '../../../Contexts/CartContext'
import { UserTheme } from '../../../Contexts/ThemeContext'

function CartModal() {
  const { theme } = UserTheme()
  const { cart, isCartOpen, setIsCartOpen, subtotal } = UserCart()
  const navigate = useNavigate()

  if (!isCartOpen) return null
  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        data-theme={theme}
        onClick={() => setIsCartOpen(false)}
      />
      <div
        className={`${styles['Modal']} ${styles[`${isCartOpen && 'open'}`]}`}
        data-theme={theme}
      >
        <div
          className={`${styles['Header']} ${styles[`${isCartOpen && 'open'}`]}`}
        >
          My Cart
        </div>
        <div
          className={`${styles['Preview']} ${
            styles[`${isCartOpen && 'open'}`]
          }`}
        >
          {cart.map((item, index) => (
            <div className={styles.Items}>
              <CartItem item={item} index={index} key={uuid()} />
            </div>
          ))}
        </div>
        <div className={styles.Footer}>
          <div className={styles.Subtotal}>Subtotal: {subtotal}</div>
          <div className={styles.Buttons_Container}>
            <Link
              className={`${styles['View']} ${
                styles[`${isCartOpen && 'open'}`]
              }`}
              to={'/cart'}
            >
              View Cart
            </Link>
            <Checkout />
          </div>
        </div>
        <div
          className={`${styles['Close']} ${styles[`${isCartOpen && 'open'}`]}`}
          onClick={() => {
            setIsCartOpen(!isCartOpen)
          }}
        >
          <div className={styles.Bar}></div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default CartModal
