import React from 'react'
import styles from './Cart.module.css'
import uuid from 'react-uuid'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BackButton from '../../../../../../../../Reusable/BackButton/BackButton'
import CartItem from './CartItem/CartItem'
import Checkout from '../../../Checkout/Checkout'
import { UserCart } from '../../../../../../../../../Contexts/CartContext'
import { UserTheme } from '../../../../../../../../../Contexts/ThemeContext'

function Cart() {
  const { cart, subtotal } = UserCart()
  const { theme } = UserTheme()

  return (
    <div className={styles.Wrapper} data-theme={theme}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
        transition={Slide}
      />
      <div className={styles.Header}>
        <BackButton />
        <div className={styles.Title}>Cart</div>
      </div>
      <div className={styles.Container}>
        <div className={styles.Cart}>
          {cart.map((item, index) => (
            <CartItem item={item} index={index} key={uuid()} />
          ))}
        </div>
        <div className={styles.Summary}>
          <div id={styles.Order_Summary}>Order Summary</div>
          <div className={styles.Subtotal}>
            <span>Subtotal</span>
            <span>${subtotal}</span>
          </div>
          <div className={styles.Shipping}>
            <span>Shipping</span>
            <span>$5</span>
          </div>
          <div className={styles.Tax}>
            <span>Estimated Tax</span>
            <span>$13.47</span>
          </div>
          <div id={styles.Promo}>
            <span>Promo Code</span>
            <div className={styles.Drop}></div>
            <div className={styles.Dropdown}>
              <input placeholder="promo code" className={styles.Input} />
              <button className={styles.Apply}>APPLY</button>
            </div>
          </div>
          <div className={styles.Total}>
            <span>Total</span>
            <span>$218.47</span>
          </div>
          <div id={styles.Savings}>
            <span>You Save</span>
            <span>$20</span>
          </div>
          {/* <button className={styles.Checkout}>Checkout</button> */}
          <Checkout />
        </div>
      </div>
    </div>
  )
}

export default Cart
