import React, { useState, useEffect } from 'react';
import styles from './Accessibility-Display-Languages.module.css';
import { UserTheme } from '../../../../../../Contexts/ThemeContext';

function AccessibilityDisplayLanguages() {
  const { theme, SwitchThemeAndLogo } = UserTheme();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const themeToggleInput = document.getElementById('switch');

    const toggleTheme = () => {
      const selectedTheme = themeToggleInput.checked ? 'dark' : 'light';
      SwitchThemeAndLogo(selectedTheme);
    };

    // Initialize the checkbox based on the current theme
    themeToggleInput.checked = theme === 'dark';

    // Remove the previous event listener to avoid multiple bindings
    themeToggleInput.removeEventListener('click', toggleTheme);

    // Add a new event listener
    themeToggleInput.addEventListener('click', toggleTheme);

    return () => {
      themeToggleInput.removeEventListener('click', toggleTheme);
    };
  }, [theme]); // Update the effect when the theme changes

  return (
    <div className={styles.Container} data-theme={theme}>
      <p className={styles.Accessibility}>Accessibility</p>
      <div className={styles.Links_Container}>
        <div className={styles.Links} id={styles.Objectionable}>
          <div className={styles.Link_Stack}>
            Select an app appearance:
            <span className={styles.Subtext}>Light Or Dark.</span>
          </div>
          <div className={styles.CheckboxContainer}>
            <input type="checkbox" id="switch" />
            <label for="switch" class={styles.ToggleLabel}>Toggle</label>
          </div>
        </div>
        <div className={styles.Links} id={styles.Objectionable}>
          <div className={styles.Link_Stack}>
            Select a Language:
            <span className={styles.Subtext}>English.</span>
          </div>
          <span className={styles.Arrow}></span>
        </div>
        <div className={styles.Links} id={styles.Objectionable}>
          <div className={styles.Link_Stack}>
            Are you enjoying Rekishi?
            <span className={styles.Subtext}>Give us a rating!</span>
          </div>
          <span className={styles.Arrow}></span>
        </div>
      </div>
    </div>
  );
}

export default AccessibilityDisplayLanguages;