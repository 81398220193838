import React, { useState } from 'react'
import styles from './Checkout.module.css'
import { loadStripe } from '@stripe/stripe-js'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'
import { UserCart } from '../../../../../../../Contexts/CartContext'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    // console.log(process.env.REACT_APP_STRIPE_KEY, 'stripe key')
    // console.log(process.env.REACT_APP_STRIPE_KEY)
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
  }

  return stripePromise
}

function Checkout() {
  const { theme } = UserTheme()
  const { isCartOpen } = UserCart()
  const [stripeError, setStripeError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const item = {
    price: 'price_1NwaCRF8DIYhCTfLaGnqQpN5',
    quantity: 1,
  }

  const checkoutOptions = {
    lineItems: [item],
    mode: 'payment',
    successUrl: `${window.location.origin}/success`,
    cancelUrl: `${window.location.origin}/cancel`,
  }

  const redirectToCheckout = async () => {
    setIsLoading(true)

    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout(checkoutOptions)
    // console.log('Stripe checkout error', error)

    if (error) setStripeError(error.message)
    setIsLoading(false)
  }

  if (stripeError) alert(stripeError)
  return (
    <button
      className={`${styles['Checkout']} ${styles[`${isCartOpen && 'open'}`]}`}
      disabled={isLoading}
      onClick={redirectToCheckout}
      data-theme={theme}
    >
      {isLoading ? 'Loading...' : 'Checkout'}
    </button>
  )
}

export default Checkout
