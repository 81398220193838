import React, { useState, useEffect } from 'react'
import styles from './Feed.module.css'
import LargeFeed from './LargeFeed/LargeFeed'
import Required from './Required/Required'
import OptionsModal from '../Profile/Modals/OptionsModal/OptionsModal'
import EditModal from '../Profile/Modals/EditModal/EditModal'
import PostModal from '../../../../../Reusable/PostModal/PostModal'
import uuid from 'react-uuid'
import { useParams, useNavigate } from 'react-router-dom'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'
import { UserAuth } from '../../../../../../Contexts/AuthContext'
import { Modals } from '../../../../../../Contexts/ModalsContext'
import { Posts } from '../../../../../../Contexts/PostsContext'

function Feed() {
  const { theme } = UserTheme()
  const {
    user,
    required,
    getUserPosts,
    getUserData,
    emailVerification,
    feedPosts,
    checkNumPhotos,
    numPhotos,
    getFeedPosts,
    convertTS,
    checkLiked,
    getPostersInfo,
  } = UserAuth()

  const { getCommentData, isPostOpen, postRef, prepPostUrl, revertUrl } =
    Posts()

  const { setIsEmailVerified } = Modals()

  const [postIDs, setPostIDs] = useState()
  const [postIndex, setPostIndex] = useState()
  const { feed } = useParams()

  useEffect(() => {
    getUserPosts()
    getUserData()
    getFeedPosts()
  }, [])

  useEffect(() => {
    setIsEmailVerified(user.emailVerified)
    if (user.email !== '') {
      // console.log(user, 'user')
    }
  }, [])

  useEffect(() => {
    if (user.emailVerified !== true) {
      emailVerification()
    } else {
      setIsEmailVerified(user.emailVerified)
    }
  }, [user.emailVerified])

  useEffect(() => {
    if (feedPosts.length > 0) {
      getPostersInfo(feedPosts)
    }
  }, [feedPosts])

  useEffect(() => {
    if (isPostOpen) prepPostUrl(postRef.current.id)
    else revertUrl('feed', feed)
  }, [isPostOpen])

  if (!required) {
    return (
      <>
        <Required />
      </>
    )
  } else {
    return (
      <div className={`${styles['Feed__Wrapper']} `} data-theme={theme}>
        <PostModal
          posts={feedPosts}
          index={postIndex}
          setPostIndex={setPostIndex}
          checkNumPhotos={checkNumPhotos}
          numPhotos={numPhotos}
        />
        <OptionsModal postIndex={postIndex} />
        <EditModal postIndex={postIndex} />
        <div className={styles.Posts__Container}>
          {feedPosts.map((post, index) => {
            return (
              <div
                className={styles.Post__Overlay}
                ref={postRef}
                id={post.PostID}
                key={uuid()}
                onClick={() => {
                  setPostIndex(index)
                  checkLiked(user.uid, post.PostID)
                  checkNumPhotos(post)
                  convertTS(post.Date)
                  //Rewritten for efficiency
                  getCommentData(post.PostID)
                }}
              >
                <LargeFeed post={post} index={index} postIDs={postIDs} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Feed
