import { createContext, useContext, useState } from
 
'react';

const ThemeContext = createContext();

export
 
const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

  const [iconTheme, setIconTheme] = useState(theme === 'dark' ? 'light' : 'dark'); // Determine icon theme based on current theme

  const [logo, setLogo] = useState(
    theme === 'dark' ? '/Rekishi-Logo-White.png' : '/Rekishi-Logo-Purple.png' // Set logo based on theme
  );

  const [navLogo, setNavLogo] = useState(
    theme === 'dark' ? '/Rekishi-Logo-White.png' : '/Rekishi-Logo-Purple.png' // Set nav logo based on theme
  );

  const SwitchThemeAndLogo = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);

    const newIconTheme = newTheme === 'dark' ? 'light' : 'dark'; // Update icon theme based on new theme
    setIconTheme(newIconTheme);

    const newLogo =
      newTheme === 'dark'
        ? '/Rekishi-Logo-White.png'
        : '/Rekishi-Logo-Purple.png'; // Update logo based on new theme
    setLogo(newLogo);

    const newNavLogo =
      newTheme === 'dark'
        ? '/Rekishi-Logo-White.png'
        : '/Rekishi-Logo-Purple.png'; // Update nav logo based on new theme
    setNavLogo(newNavLogo);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        SwitchThemeAndLogo,
        logo,
        navLogo,
        setIconTheme,
        iconTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const UserTheme = () => {
  return useContext(ThemeContext);
};

// Component that calls UserTheme()
const MyComponent = () => {
  const theme = UserTheme();

  return (
    <div>
      <h1>My Component</h1>
      <p>Theme: {theme}</p>
    </div>
  );
};