import React, { useEffect, useRef } from 'react';
import styles from './Episodes.module.css';
import ReactDOM from 'react-dom';
import { db } from '../../../../../../../../firebase';
import { ref as dbRef, update } from 'firebase/database';
import { Modals } from '../../../../../../../../Contexts/ModalsContext';
import { UserTheme } from '../../../../../../../../Contexts/ThemeContext';
import { UserAuth } from '../../../../../../../../Contexts/AuthContext';

function Episodes({ mediaID, numViews, episodeIndex }) {
  const { isEpisodesOpen, setIsEpisodesOpen } = Modals();
  const { episodes } = UserAuth();
  const { theme } = UserTheme();
  const videoRef = useRef(null);

  useEffect(() => {
    if (isEpisodesOpen) {
      const video = videoRef.current;
      video.requestFullscreen();
      // console.log(video, 'video');
    }
  }, [isEpisodesOpen]);

  const updateViews = (ID, numViews) => {
    update(dbRef(db, 'the-media-posts/' + ID), {
      Views: numViews + 1,
    });
  }

  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') {
      closeVideoPlayer();
    }
  };

  const closeVideoPlayer = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
    }
    setIsEpisodesOpen(false);
    updateViews(mediaID, numViews);
  };

  useEffect(() => {
    // Add an event listener for the "Escape" key
    document.addEventListener('keydown', handleEscapeKey);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isEpisodesOpen, mediaID, numViews]);

  if (!isEpisodesOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        onClick={() => {
          closeVideoPlayer();
        }}
        data-theme={theme}
      />
      <div className={styles.Modal} data-theme={theme}>
        <div className={styles.VideoCanvas}>
          <video
            id="video"
            ref={videoRef}
            controls
            src={episodes[episodeIndex]}
            autoPlay={true}
            type={'video/mp4'}
            className={styles.Video}
          />
        </div>
      </div>
    </>,
    document.getElementById('portal')
  );
}

export default Episodes;
