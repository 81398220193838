import React, { useEffect, useState } from 'react'
import styles from './Profile.module.css'
import uuid from 'react-uuid'
import { db } from '../../../../../../firebase'
import { ref as dbRef, onValue } from 'firebase/database'
import PostType0 from '../../../../../Reusable/PostTypes/PostType0'
import PostType1 from '../../../../../Reusable/PostTypes/PostType1'
import PostType2 from '../../../../../Reusable/PostTypes/PostType2'
import PostType5 from '../../../../../Reusable/PostTypes/PostType5'
import { Link, useParams } from 'react-router-dom'
import Post from '../Post/Post'
import PostModal from '../../../../../Reusable/PostModal/PostModal'
import ProfileModal from './Modals/ProfileModal/ProfileModal'
import BannerModal from './Modals/BannerModal/BannerModal'
import OptionsModal from './Modals/OptionsModal/OptionsModal'
import EditModal from './Modals/EditModal/EditModal'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'
import { Posts } from '../../../../../../Contexts/PostsContext'
import { UserAuth } from '../../../../../../Contexts/AuthContext'
import UrlPreview from '../../../Navbar/Pages/CreatePost/UrlPreview/UrlPreview'

function Profile() {
  const {
    getPosts,
    posts,
    getCommentData,
    isPostOpen,
    setIsPostOpen,
    postRef,
    prepPostUrl,
    revertUrl,
  } = Posts()
  const {
    user,
    numPhotos,
    checkNumPhotos,
    getCommentIDs,
    checkLiked,
    convertTS,
  } = UserAuth()
  const { theme } = UserTheme()

  const [currentUser, setCurrentUser] = useState()
  const [postIDs, setPostIDs] = useState()
  const [postIndex, setPostIndex] = useState()
  const [followers, setFollowers] = useState()
  const [following, setFollowing] = useState()
  const { profile } = useParams()

  useEffect(() => {
    const getUserData = (ID) => {
      onValue(dbRef(db, 'users/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setCurrentUser(data)
      })
    }

    const createPostLinks = (post, index) => {
      const postTypes = [
        <PostType0 post={post} index={index} />,
        <PostType1 post={post} index={index} />,
        ,
        <PostType2 post={post} index={index} />,
        ,
        ,
        <PostType5 post={post} index={index} />,
      ]
      for (let i = 0; i < postTypes.length; i++) {
        return (
          <Link
            ref={postRef}
            id={post.PostID}
            key={uuid()}
            onClick={() => {
              setIsPostOpen(true)
              setPostIndex(index)
              getCommentIDs(post.PostID)
              checkLiked(user.uid, posts[index].PostID)
              checkNumPhotos(post)
              convertTS(post.Date)
              getCommentData(post.PostID)
            }}
          >
            {postTypes[i]}
          </Link>
        )
      }
    }

    const getUserPostsFollowersFollowing = (ID) => {
      onValue(dbRef(db, 'user-posts/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setPostIDs(data)
      })

      onValue(dbRef(db, 'user-followers/' + ID), async (snapshot) => {
        if (snapshot.exists()) {
          const data = await snapshot.val()
          setFollowers(Object.values(data).length)
        }
      })

      onValue(dbRef(db, 'user-following/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setFollowing(Object.values(data).length)
      })
    }

    getUserData(user.uid)
    getUserPostsFollowersFollowing(user.uid)
  }, [])

  useEffect(() => {
    if (postIDs) getPosts(Object.keys(postIDs))
  }, [postIDs])

  useEffect(() => {
    if (isPostOpen) prepPostUrl(postRef.current.id)
    else revertUrl('profile', profile)
  }, [isPostOpen])

  if (!currentUser) return null

  return (
    <div className={styles.Wrapper} data-theme={theme}>
      <div className={styles.Banner__Container}>
        <img
          className={styles.BannerIMG}
          src={currentUser.bannerImageUrl}
          alt="banner"
        />
      </div>
      <div className={styles.Info__Container}>
        <div className={styles.Left__Info}>
          <img
            className={styles.ProfileIMG}
            src={currentUser.profileImageUrl}
            alt="profile"
          />
          <div className={styles.Name__Bio}>
            <div className={styles.Username}>{'@' + currentUser.username}</div>
            <div className={styles.Description}>
              {currentUser.profileDescription
                ? currentUser.profileDescription
                : 'No Description Yet'}
            </div>
          </div>
        </div>
        <div className={styles.Right__Info}>
          <div className={styles.Stats__Container}>
            <div className={styles.Stats__Item}>
              <div className={styles.Stat}>
                {postIDs ? Object.values(postIDs).length : 'Loading'}
              </div>
              <div className={styles.Stat__Title}>Posts</div>
            </div>
            <div className={styles.Stats__Item}>
              <div className={styles.Stat}>{!followers ? 0 : followers}</div>
              <div className={styles.Stat__Title}>Followers</div>
            </div>
            <div className={styles.Stats__Item}>
              <div className={styles.Stat}>{following}</div>
              <div className={styles.Stat__Title}>Following</div>
            </div>
          </div>
        </div>
      </div>
      <Post postIndex={postIndex} />
      <PostModal
        posts={posts}
        index={postIndex}
        setPostIndex={setPostIndex}
        checkNumPhotos={checkNumPhotos}
        numPhotos={numPhotos}
      />
      <ProfileModal />
      <BannerModal />
      <OptionsModal index={postIndex} />
      <EditModal index={postIndex} />
      <div className={styles.Posts__Container}>
        {posts.map((post, index) =>
          !post ? (
            <div className={styles.null}></div>
          ) : post.Type === 1 ? (
            <Link
              ref={postRef}
              id={post.PostID}
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType1 post={post} index={index} />
            </Link>
          ) : post.Type === 2 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType2 post={post} index={index} />
            </Link>
          ) : post.Type === 0 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType0 post={post} index={index} />
            </Link>
          ) : post.Type === 5 ? (
            <>
              <UrlPreview url={post.theUrl} />
              <Link
                key={uuid()}
                onClick={() => {
                  setIsPostOpen(true);
                  setPostIndex(index);
                  getCommentIDs(post.PostID);
                  checkLiked(user.uid, posts[index].PostID);
                  checkNumPhotos(post);
                  convertTS(post.Date);
                  getCommentData(post.PostID);
                }}
              >
                <PostType5 post={post} index={index} />
              </Link>
            </>
          ) : null
        )}
      </div>
    </div>
  )
}

export default Profile
