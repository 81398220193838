import React from 'react'
import ReactDOM from 'react-dom'
import styles from './EmailVerify.module.css'
import { UserAuth } from '../../../Contexts/AuthContext'
import { Modals } from '../../../Contexts/ModalsContext'

function EmailVerify() {
  const { isEmailVerified, setIsEmailVerified } = Modals()
  const { currentUser, emailVerification } = UserAuth()

  if (isEmailVerified) return null
  return ReactDOM.createPortal(
    <>
      <div className={styles.Overlay} />
      <div className={styles.Modal}>
        <div className={styles.Header}>Verify Your Email Address</div>
        <div className={styles.Body}>
          <p className={styles.Text}>
            Our records show that you have not yet verified your email address.
            An email with a verification link has been sent to the email address
            you signed up with. Please verify your email address to proceed. If
            you are not seeing the email check your spam folders.
          </p>
          <p className={styles.Resend}>
            You can also have another verification email sent by clicking the
            button below.
          </p>
          <button
            onClick={() => {
              // console.log('clicked')
              emailVerification()
            }}
          >
            Resend Verification Email
          </button>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default EmailVerify
