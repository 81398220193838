import React from 'react'
import styles from './ShopItems.module.css'
import { Link } from 'react-router-dom'
import LikeIcon from '../../../../../../Reusable/LikeIcon/LikeIcon'

function ShopPost({ post }) {
  return (
    <>
      <Link className={styles.Container} to={'/item/' + post.ShopPostID}>
        <img src={post.Image} alt={``} className={styles.Image} />
      </Link>
      <div className={styles.Footer}>
        <Link className={styles.Name} to={'/item/' + post.ShopPostID}>
          {post.Name}
        </Link>
        <div className={styles.Footer_Bottom}>
          <Link className={styles.Price} to={'/item/' + post.ShopPostID}>
            {'$' + post.Price}
          </Link>
          <div className={styles.Likes_Container}>
            <div className={styles.Likes}>{post.Likes}</div>
            <LikeIcon post={post} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopPost
