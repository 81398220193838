import { createContext, useContext, useState, useRef } from 'react'
import { ref as dbRef, onValue, update } from 'firebase/database'
import { db } from '../firebase'

const MediasContext = createContext()

export const MediasContextProvider = ({ children }) => {
  //Like a Media Community as current user
  const [isMediaOpen, setIsMediaOpen] = useState(false)
  const [comments, setComments] = useState()
  const [commenters, setCommenters] = useState([])
  const [media, setMedia] = useState([])
  const mediaRef = useRef()
  const likeMedia = (UserID, MediaID, numLikes, liked) => {
    if (liked) {
      update(dbRef(db, 'media-like/' + MediaID), {
        [UserID]: null,
      })

      update(dbRef(db, 'the-media-posts/' + MediaID), {
        Likes: numLikes - 1,
      })
    } else {
      update(dbRef(db, 'media-like/' + MediaID), {
        [UserID]: 1,
      })

      update(dbRef(db, 'the-media-posts/' + MediaID), {
        Likes: numLikes + 1,
      })
    }
  }
  const mediaIDRef = useRef();
  
  const getMedia = (MediaID) => {
    setMedia([]);
    mediaIDRef.current = MediaID;
    for (let i = MediaID.length - 1; i > -1; i--) {
      onValue(dbRef(db, 'the-media-posts/' + MediaID[i]), async (snapshot) => {
        const data = await snapshot.val();
        setMedia((media) => [...media, data]);
      });
    }
  };

  /* Returns comments in an array from newest to oldest. */
  const getCommentData2 = async (ID) => {
    setComments()
    setCommenters([])
    /* Gets corresponding Username and Photo for each comment */
    const getUsernameAndPhoto = (ID) => {
      const ref = dbRef(db, 'users/' + ID)
      onValue(ref, async (snapshot) => {
        const data = await snapshot.val()
        setCommenters((commenters) => [...commenters, data])
      })
    }

    /* Gets Comment, Date, and Likes */
    const ref = dbRef(db, 'media-comments/' + ID)
    onValue(ref, (snapshot) => {
      const data = Object.entries(snapshot.val())
      setComments(data)
      data.forEach((comment) => {
        getUsernameAndPhoto(comment[1].User)
      })
    })
  }

  /* Preps the browser URL to navigate to the posts page on 
    refresh when post modal is opened*/
    const prepPostUrls = (ID) => {
      window.history.replaceState(100, 'media', 'comments/' + ID);
    }

  /* Reverts the browser URL to stay on the current page when 
    post modal is closed */
  const revertUrls = (route, param) => {
    if (param) {
      window.history.replaceState(99, 'param', `/${route}/` + `${param}`)
    } else {
      window.history.replaceState(98, 'route', `/${route}`)
    }
  }

  /* Clears comment data*/
  const clearCommentsData = () => {
    setComments()
    setCommenters([])
  }

  return (
    <MediasContext.Provider
      value={{
        likeMedia,
        getCommentData2,
        clearCommentsData,
        comments,
        commenters,
        getMedia,
        media,
        setMedia,
        isMediaOpen,
        setIsMediaOpen,
        mediaIDRef,
        prepPostUrls,
        revertUrls,
      }}
    >
      {children}
    </MediasContext.Provider>
  )
}

export const Medias = () => {
  return useContext(MediasContext)
}
