import React, { useState } from 'react'
import styles from './Return.module.css'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'

function Return() {
  const { theme, SwitchThemeAndLogo, logo } = UserTheme()

  return (
    <div className={styles.Return__Wrapper}>
      <img
        src={logo}
        alt="logo"
        width="300"
        height="138"
        className={styles.Logo}
        data-theme={theme}
      />
    </div>
  )
}

export default Return
