import React, { useState, useEffect } from 'react'
import styles from './LikeIcon.module.css'
import { db } from '../../../firebase'
import { ref as dbRef, onValue, update } from 'firebase/database'
import { useParams } from 'react-router-dom'
import { UserAuth } from '../../../Contexts/AuthContext'
import { UserTheme } from '../../../Contexts/ThemeContext'

function LikeIcon({ post }) {
  const { user } = UserAuth()
  const { theme } = UserTheme()

  const [path] = useState(window.location.pathname)
  const [dbPath, setDbPath] = useState()
  const [likePath, setLikePath] = useState()
  const [liked, setLiked] = useState(false)
  const [iconColor, setIconColor] = useState(false)

  const { shoppostid } = useParams()

  const checkLiked = (postID) => {
    onValue(dbRef(db, `${dbPath}` + postID), async (snapshot) => {
      const data = await snapshot.val();
      if (data !== null) {
        if (Object.keys(data).includes(user.uid)) {
          setLiked(true);
        }
      }
    });
  }

  const likeUnlike = (ID, numLikes) => {
    if (liked) {
      update(dbRef(db, likePath + ID), {
        Likes: numLikes - 1,
      })
      update(dbRef(db, dbPath + ID), {
        [user.uid]: null,
      })
      setLiked(false)
    } else {
      update(dbRef(db, likePath + ID), {
        Likes: numLikes + 1,
      })
      update(dbRef(db, dbPath + ID), {
        [user.uid]: 1,
      })
    }
  }

  const likeHandler = () => {
    if (post.hasOwnProperty('PostID')) {
      likeUnlike(post.PostID, post.Likes)
    } else if (post.hasOwnProperty('ShopPostID')) {
      likeUnlike(post.ShopPostID, post.Likes)
    } else {
      likeUnlike(post[9], post[7])
    }
  }

  useEffect(() => {
    switch (window.location.pathname) {
      case '/feed':
        setDbPath('/post-like/')
        setLikePath('/the-posts/')
        break
      case '/profile':
        setDbPath('/post-like/')
        setLikePath('/the-posts/')
        break
      case '/user':
        setDbPath('/post-like/')
        setLikePath('/the-posts/')
        break
      case '/community':
        setDbPath('/post-like/')
        setLikePath('/the-posts/')
        break
      case '/shop':
        setDbPath('/shop-like/')
        setLikePath('/the-shop-posts/')
        break
      case `/item/${shoppostid}`:
        setDbPath('/shop-like/')
        setLikePath('/the-shop-posts/')
        break
      case '/media':
        setDbPath('/media-like/')
        setLikePath('/the-media-posts/')
        break
    }
  }, [])

  useEffect(() => {
    const pathsToCheck = ['/community', '/profile', '/user']

    if (pathsToCheck.some((pathToCheck) => path.includes(pathToCheck))) {
      setIconColor(true)
    }
  }, [path])

  useEffect(() => {
    if (post.hasOwnProperty('PostID')) {
      checkLiked(post.PostID)
    } else if (post.hasOwnProperty('ShopPostID')) {
      checkLiked(post.ShopPostID)
    } else {
      checkLiked(post[9])
    }
  }, [dbPath])

  return (
    <button
      className={`${styles['Like_Icon']} ${styles[`${liked && 'liked'}`]}`}
      id={`${styles[iconColor && 'iconColor']}`}
      data-theme={theme}
      onClick={() => {
        likeHandler()
      }}
    ></button>
  )
}

export default LikeIcon
