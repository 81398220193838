import React, { useEffect } from 'react';
import styles from './Search.module.css';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import { UserTheme } from '../../../../../../Contexts/ThemeContext';
import { UserAuth } from '../../../../../../Contexts/AuthContext';
import { useSearch2 } from '../../SearchProvider2';

function Search() {
  const {
    comsList,
    animeFollowers,
    filteredComs,
    setFilteredComs,
    setCommunity,
  } = UserAuth();

  const { theme } = UserTheme();
  const { search2, setSearch2 } = useSearch2();

  const filter = (com) => {
    const followers = animeFollowers.filter((anime) => anime[0] === com[0]);
    if (followers[0]) {
      return Object.keys(followers[0][1]).length;
    } else {
      return 0;
    }
  }

  // Load the search text and filter from local storage on initial render
  useEffect(() => {
    const storedSearch = localStorage.getItem('search');
    if (storedSearch) {
      setSearch2(storedSearch);
    }
  }, [setSearch2]);

  useEffect(() => {
    if (comsList) {
      setFilteredComs(comsList);
    }
  }, [comsList]);

  useEffect(() => {
    if (comsList) {
      setFilteredComs(
        comsList.filter((com) =>
          com[0].toLowerCase().includes(search2.toLowerCase())
        )
      );

      // Save the search text to local storage whenever it changes
      localStorage.setItem('search', search2);
    }
  }, [search2, comsList]);

  return (
    <div className={styles.Feed__Container} data-theme={theme}>
      <div className={styles.Search__Item}>
        {filteredComs && animeFollowers ? (
          filteredComs.map((com, index) => (
            <Link
              to={'/community/' + com[2]}
              id={styles.Search__Item}
              key={uuid()}
              onClick={() => setCommunity(com)}
            >
              {index > 0 &&
              com[0].charAt(0) === filteredComs[index - 1][0].charAt(0) ? (
                <div></div>
              ) : (
                <div className={styles.Alphabetical}>{com[0].charAt(0)}</div>
              )}
              <div className={styles.Community__Container} id={styles.item}>
                <img className={styles.Image} src={com[3]} />
                <div className={styles.Community}>{com[0]}</div>
                <div className={styles.About}>{com[1]}</div>
                <div className={styles.Followers}>
                  {filter(com) + ' Members 2'}
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className={styles.Loading}>{'Loading...'}</div>
        )}
      </div>
    </div>
  );
}

export default Search;