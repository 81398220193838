import React from 'react'
import styles from './Security.module.css'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'

function Security() {
  const { theme } = UserTheme()
  return (
    <div className={styles.Container} data-theme={theme}>
      <p className={styles.Title}>Security</p>
      <div className={styles.Options_Container}>
        <p className={styles.Info}>
          Help keep your account safe by adding another layer of protection with
          two-factor authentication. This will help to prevent someone else from
          accessing your account, even if they figure out your password.
        </p>
        <p id={styles.twoFactor}>Two-factor authentication is: Disabled.</p>
        <p id={styles.status}>Enable</p>
      </div>
    </div>
  )
}

export default Security
