import React, { useState } from 'react'
import styles from './Sequences.module.css'
import ReactDOM from 'react-dom'
import { Modals } from '../../../../../../../../Contexts/ModalsContext'
import { UserTheme } from '../../../../../../../../Contexts/ThemeContext'
import { UserAuth } from '../../../../../../../../Contexts/AuthContext'

function Sequences({ mediaID, sequenceIndex }) {
  const { isSequencesOpen, setIsSequencesOpen } = Modals()
  const { episodes } = UserAuth()
  const { theme } = UserTheme()

  const [index, setIndex] = useState(1)

  if (isSequencesOpen === false) return null

  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        onClick={() => {
          setIsSequencesOpen(false)
          setIndex(1)
        }}
        data-theme={theme}
      />
      <div className={styles.Modal} data-theme={theme}>
        <div className={styles.SequenceCanvas}>
          {index === episodes[sequenceIndex].length - 1 ? (
            <div></div>
          ) : (
            <div
              className={styles.Nav}
              id={styles.Forward}
              onClick={() => {
                if (index < episodes[sequenceIndex].length) {
                  setIndex(index + 1)
                }
              }}
            ></div>
          )}
          <img
            src={episodes[sequenceIndex][index]}
            alt="Sequence"
            className={styles.Sequences}
          />
          {index === 1 ? (
            <div></div>
          ) : (
            <div
              className={styles.Nav}
              id={styles.Back}
              onClick={() => {
                if (index > 1) {
                  setIndex(index - 1)
                }
              }}
            ></div>
          )}
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Sequences