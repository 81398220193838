import React, { useState, useEffect } from 'react'
import styles from './Item.module.css'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { db } from '../../../../../../../../firebase'
import { ref as dbRef, onValue } from 'firebase/database'
import { Link, useParams } from 'react-router-dom'
import BackButton from '../../../../../../../Reusable/BackButton/BackButton'
import CartModal from '../../../../../../../Reusable/CartModal/CartModal'
import LikeIcon from '../../../../../../../Reusable/LikeIcon/LikeIcon'
import LoadingSpinner from '../../../../../../../Reusable/LoadingSpinner/LoadingSpinner'
import { UserTheme } from '../../../../../../../../Contexts/ThemeContext'
import { UserCart } from '../../../../../../../../Contexts/CartContext'

function Item() {
  const { theme } = UserTheme()
  const {
    cart,
    setCart,
    itemQuantities,
    setItemQuantities,
    isCartOpen,
    setIsCartOpen,
  } = UserCart()

  const [item, setItem] = useState()
  const [seller, setSeller] = useState()

  const { shoppostid } = useParams()

  const getSeller = (ID) => {
    onValue(dbRef(db, 'users/' + ID), async (snapshot) => {
      const data = await snapshot.val()
      setSeller(data)
    })
  }

  const getItem = (ID) => {
    onValue(dbRef(db, 'the-shop-posts/' + ID), async (snapshot) => {
      const data = await snapshot.val()
      setItem(data)
      getSeller(data.User)
    })
  }

  const addItemToCart = (item) => {
    if (!isItemInCart(item)) {
      setCart((cart) => [...cart, item])
    } else {
      // console.log('Item is already in the cart.')
    }

    let updatedQuantities = [...itemQuantities]

    const keyToCheck = item.ShopPostID
    const keyExists = updatedQuantities.some((obj) =>
      obj.hasOwnProperty(keyToCheck)
    )
    const keyIndex = updatedQuantities.findIndex((obj) =>
      obj.hasOwnProperty(keyToCheck)
    )

    if (keyExists) {
      if (updatedQuantities[keyIndex][keyToCheck] < item.Quantity) {
        updatedQuantities[keyIndex][keyToCheck] += 1
        setItemQuantities(updatedQuantities)
        toast.success('Item successfully added to your cart!')
        setIsCartOpen(true)
      } else {
        toast.error(
          'You already have the maximum quantity of this item in your cart.'
        )
      }
    } else {
      updatedQuantities.push({ [item.ShopPostID]: 1 })
      setItemQuantities(updatedQuantities)
      toast.success('Item successfully added to your cart!')
      setIsCartOpen(true)
    }
  }

  const isItemInCart = (item) => {
    return cart.some((cartItem) => cartItem.ShopPostID === item.ShopPostID)
  }

  useEffect(() => {
    if (shoppostid) getItem(shoppostid)
  }, [shoppostid])

  useEffect(() => {
    // console.log(itemQuantities, 'itemQuantities')
  }, [itemQuantities])

  if (!item || !seller) return <LoadingSpinner />
  return (
    <div className={styles.Wrapper} data-theme={theme}>
      <CartModal />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
        transition={Slide}
      />
      <div className={styles.Header}>
        <BackButton />
        <div className={styles.Item}>{item.Name}</div>
        {/* <Link className={styles.Cart} to={'/cart'}></Link> */}
      </div>
      <div className={styles.Main}>
        <div className={styles.Images_Container}>
          <img className={styles.Image} src={item.Image} />
        </div>
        <div className={styles.Info_Container}>
          <div className={styles.Seller_Container}>
            <div className={styles.Seller}>
              <span>Seller:</span>&nbsp;{seller.username}
            </div>
            <div className={styles.Like_Container}>
              {item.Likes} <LikeIcon post={item} />
            </div>
          </div>
          <div className={styles.About}>
            <span>Item Description:</span>&nbsp;{item.About}
          </div>
          <div className={styles.Condition}>
            <span>Item Condition:</span>&nbsp;{item.Condition}
          </div>
          <div className={styles.Quantity}>
            <span>Item Quantity:</span>&nbsp;{item.Quantity}
          </div>
          <div className={styles.Price}>
            <span>Price:</span>&nbsp;{item.Price}
          </div>
          <div className={styles.Shipping}>
            <span>Shipping:</span>&nbsp;{item.Shipping}
          </div>
          {/* <div className={styles.Buttons}>
            <button className={styles.Button}>Buy Now</button>
            <button
              className={styles.Button}
              onClick={() => addItemToCart(item)}
            >
              Add to Cart
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Item
