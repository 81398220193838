import React, { useState, useEffect } from 'react';
import styles from './Shop.module.css';
import ShopItems from './ShopItems/ShopItems';
import { db } from '../../../../../../firebase';
import { ref as dbRef, onValue } from 'firebase/database';
import uuid from 'react-uuid';
import LoadingSpinner from '../../../../../Reusable/LoadingSpinner/LoadingSpinner';
import { UserTheme } from '../../../../../../Contexts/ThemeContext';
import { useSearch3 } from '../../SearchProvider3';

function Shop() {
  const { theme } = UserTheme();
  const { search3 } = useSearch3(); // Use the search context to get the search term

  const [posts, setPosts] = useState([]);

  const getShopPosts = () => {
    onValue(dbRef(db, '/the-shop-posts/'), async (snapshot) => {
      const data = await snapshot.val();
      setPosts(Object.values(data));
    });
  }

  useEffect(() => {
    getShopPosts();
  }, []);

  // Filter the shop posts by their title based on the search term
  const filteredPosts = posts.filter((post) =>
    post.Name.toLowerCase().includes(search3.toLowerCase())
  );

  if (posts.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <div className={`${styles['Wrapper']} `} data-theme={theme}>
      <div className={styles.Container}>
        {filteredPosts.map((post, index) => (
          <div className={styles.Item} key={uuid()}>
            <ShopItems post={post} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Shop;