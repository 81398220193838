import React from 'react'
import styles from './Content.module.css'
import { UserTheme } from '../../../../../../Contexts/ThemeContext.js'

function Content() {
  const { theme } = UserTheme()
  return (
    <div className={styles.Container} data-theme={theme}>
      <p className={styles.Content}>Content</p>
      <div className={styles.Links_Container}>
        <button className={styles.Links} id={styles.Objectionable}>
          <div className={styles.Link_Stack}>
            View Objectionable Content (Posts, Media, Shop)
            <span className={styles.Subtext}>No.</span>
          </div>
          <span className={styles.Arrow}></span>
        </button>
        <button className={styles.Links}>
          <div className={styles.Link_Stack}>
            View All Blocked Users
            <span className={styles.Subtext}>Unblock or block them.</span>
          </div>
          <span className={styles.Arrow}></span>
        </button>
        <button className={styles.Links}>
          <div className={styles.Link_Stack}>
            Notifications
            <span className={styles.Subtext}>View and edit settings.</span>
          </div>
          <span className={styles.Arrow}></span>
        </button>
        <button className={styles.Links}>
          <div className={styles.Link_Stack}>
            Direct Messages
            <span className={styles.Subtext}>View and edit settings.</span>
          </div>
          <span className={styles.Arrow}></span>
        </button>
        <button className={styles.Links}>
          <div className={styles.Link_Stack}>
            Sort
            <span className={styles.Subtext}>View and edit settings.</span>
          </div>
          <span className={styles.Arrow}></span>
        </button>
        <button className={styles.Links} id={styles.cache}>
          Clear Cache
          <span className={styles.Cache}>27.60 MB</span>
        </button>
      </div>
    </div>
  )
}

export default Content
