import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './OptionsModal.module.css';
import { Modals } from '../../../../../../../../Contexts/ModalsContext';
import { UserAuth } from '../../../../../../../../Contexts/AuthContext';
import { UserTheme } from '../../../../../../../../Contexts/ThemeContext';
import { ref as dbRef, update } from 'firebase/database'
import { db } from '../../../../../../../../firebase'
import clipboardCopy from 'clipboard-copy';

function OptionsModal({ postIndex }) {
  const {
    user,
    commentOption,
    deleteComment,
    postObjects,
    setEditedComment,
  } = UserAuth();
  const { setIsOptionsOpen, setIsEditOpen } = Modals();
  const { isOptionsOpen } = useState();
  const { theme } = UserTheme();
  console.log('isOptionsOpen:', isOptionsOpen);
  console.log('setIsOptionsOpen:', setIsOptionsOpen);
  console.log('setIsEditOpen:', setIsEditOpen);

  if (!isOptionsOpen) return null;

  const handleReportComment = async (comment, user) => {
    try {
      // Check if comment and user are defined
      if (!comment || !user) {
        // console.error('Invalid comment or user', comment, user);
        return;
      }
  
      // Construct the path for the report reference
      const reportRefPath = `/reported-comments/${comment[1].CommentID}`;
      // Get a reference to the reported-comments node
      const reportRef = dbRef(db, reportRefPath);
  
      // Get the current timestamp
      const theDate = Math.floor(Date.now() / 1000);
  
      // Construct the data to be uploaded
      const uploadThis = {
        Reported: true,
        Date: theDate,
        Comment: comment[1].Comment,
        Uid: comment[1].User,
        Sub: comment[1].Sub,
        Reporter: user,
      };
  
      // Update the data at the specified path
      await update(reportRef, uploadThis);

      // Close the modal immediately
      setIsOptionsOpen(false);
      setTimeout(() => {
        alert('Comment reported successfully. Please give our team 24 hours to work on the request.');
      }, 500);
      } catch (error) {
      console.error('Error reporting comment:', error.message);
      }
  };

  const handleShareComment = async (comment) => {
    try {
      // Check if comment is defined
      if (!comment) {
        console.error('Invalid comment', comment);
        return;
      }

      // Generate a shareable link or content
      const shareableContent = `Check out this comment: ${comment[1].Comment}`;

      // Copy the content to the clipboard
      clipboardCopy(shareableContent);

      // Show a success message
      alert('Comment copied to clipboard.');

      // Close the modal immediately
      setIsOptionsOpen(false);
    } catch (error) {
      console.error('Error sharing comment:', error.message);
    }
  };

  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        onClick={() => setIsOptionsOpen(false)}
        data-theme={theme}
      />
      <div className={styles.Options} data-theme={theme}>
        {commentOption.User === user.uid ? (
          <button
            className={styles.Options__Button}
            onClick={() => {
              setIsEditOpen(true);
              setEditedComment(commentOption.Comment);
            }}
          >
            Edit Comment
          </button>
        ) : (
          <button
            className={styles.Options__Button}
            onClick={() => handleShareComment(commentOption)}
          >
            Share Comment
          </button>
        )}
        {commentOption.User === user.uid ? (
          <button
            className={styles.Options__Button}
            onClick={() =>
              deleteComment(
                postObjects[postIndex].PostID,
                postObjects[postIndex]['Anime-Sub'],
                commentOption.CommentID
              )
            }
          >
            Delete Comment
          </button>
        ) : (
          <button
          className={styles.Options__Button}
          onClick={() => handleReportComment(commentOption, user.uid)}
        >
          Report Comment
        </button>
        )}
        <button
          className={styles.Options__Button}
          onClick={() => setIsOptionsOpen(false)}
        >
          Cancel
        </button>
      </div>
    </>,
    document.getElementById('portal')
  );
}

export default OptionsModal;
