import React, { useState, useEffect } from 'react'
import styles from './CreatePost.module.css'
import uuid from 'react-uuid'
import { Timestamp } from 'firebase/firestore'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'
import { UserAuth } from '../../../../../../Contexts/AuthContext'
import { Modals } from '../../../../../../Contexts/ModalsContext'
import MediaModal from './PostModals/MediaModal'
import UrlPreview from './UrlPreview/UrlPreview'

function Post() {
  const { theme } = UserTheme()
  const {
    comsList,
    preview,
    setPreview,
    setImageForm,
    newPost,
    setNewPost,
    resetNewPost,
    postToStorage,
  } = UserAuth()
  const { isPreviewOpen, setIsPreviewOpen } = Modals()
  const [type, setType] = useState('Text')
  const [communityFix, setCommunityFix] = useState('--Community--')
  const [postButton, setPostButton] = useState(true)
  const [previewIndex, setPreviewIndex] = useState(0)
  const [url, setUrl] = useState('')

  useEffect(() => {
    switch (preview.length) {
      case 0:
        resetNewPost()
        break
      case 3:
        setNewPost({
          ...newPost,
          ImageHeight: preview[1],
          ImageWidth: preview[2],
        })
        break
      case 6:
        setNewPost({
          ...newPost,
          ImageHeight: preview[1],
          ImageWidth: preview[2],
          ImageHeight2: preview[4],
          ImageWidth2: preview[5],
        })
        break
      case 9:
        setNewPost({
          ...newPost,
          ImageHeight: preview[1],
          ImageWidth: preview[2],
          ImageHeight2: preview[4],
          ImageWidth2: preview[5],
          ImageHeight3: preview[7],
          ImageWidth3: preview[8],
        })
        break
      case 12:
        setNewPost({
          ...newPost,
          ImageHeight: preview[1],
          ImageWidth: preview[2],
          ImageHeight2: preview[4],
          ImageWidth2: preview[5],
          ImageHeight3: preview[7],
          ImageWidth3: preview[8],
          ImageHeight4: preview[10],
          ImageWidth4: preview[11],
        })
        break
      case 15:
        setNewPost({
          ...newPost,
          ImageHeight: preview[1],
          ImageWidth: preview[2],
          ImageHeight2: preview[4],
          ImageWidth2: preview[5],
          ImageHeight3: preview[7],
          ImageWidth3: preview[8],
          ImageHeight4: preview[10],
          ImageWidth4: preview[11],
          ImageHeight5: preview[10],
          ImageWidth5: preview[11],
        })
        break
    }
  }, [preview])

  useEffect(() => {
    if (
      newPost.Title.length > 0 &&
      newPost.Title.length < 1000 &&
      newPost['Anime-Sub'].length > 0 &&
      newPost['Anime-Sub'] !== '--Community--'
    ) {
      setPostButton(false)
    } else {
      setPostButton(true)
    }
  }, [newPost])

  useEffect(() => {
    setCommunityFix(newPost['Anime-Sub'])
  }, [newPost['Anime-Sub']])

  useEffect(() => {
    if (isPreviewOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isPreviewOpen])

  const getCommunityID = (e) => {
    const com = comsList.filter((com) => com[0] === e.target.value)
    const comID = com[0][2]
    setNewPost({
      ...newPost,
      CommunityID: comID,
      ['Anime-Sub']: e.target.value,
    })
  }

  //Added Function
  const handleUrlChange = (event) => {
    setUrl(event.target.value)
  }

  return (
    <div className={styles.Post__Wrapper} data-theme={theme}>
      <div className={styles.Post__Container} data-theme={theme}>
        <div
          className={styles.Type__Nav}
          id={styles.TypeNav}
          data-theme={theme}
        >
          {/* 
          The conditional statements below determine the 
          styling of active and non active nav bar buttons
          */}
          {type === 'Text' ? (
            <div
              className={styles.Nav__Button}
              id={styles.Current}
              onClick={() => {
                setType('Text')
                resetNewPost()
              }}
            >
              Text
            </div>
          ) : (
            <div
              className={styles.Nav__Button}
              onClick={() => {
                setType('Text')
                resetNewPost()
              }}
            >
              Text
            </div>
          )}
          {type === 'Media' ? (
            <div
              className={styles.Nav__Button}
              id={styles.Current}
              onClick={() => {
                setType('Media')
                resetNewPost()
              }}
            >
              Media
            </div>
          ) : (
            <div
              className={styles.Nav__Button}
              onClick={() => {
                setType('Media')
                resetNewPost()
              }}
            >
              Media
            </div>
          )}
          {type === 'Url' ? (
            <div
              className={styles.Nav__Button}
              id={styles.Current}
              onClick={() => {
                setType('Url')
                resetNewPost()
              }}
            >
              Url
            </div>
          ) : (
            <div
              className={styles.Nav__Button}
              onClick={() => {
                setType('Url')
                resetNewPost()
              }}
            >
              Url
            </div>
          )}
        </div>
        {/* 
        This code determines which type of post interface the user sees 
        based on what button has been pressed on the nav bar above
        */}
        <div className={styles.Variable__Post} data-theme={theme}>
          {type === 'Text' ? (
            <div className={styles.Variable__Container} data-theme={theme}>
              <form>
                <input
                  className={styles.Title}
                  type="text"
                  placeholder="Title"
                  maxLength={1000}
                  onChange={(e) =>
                    setNewPost({ ...newPost, Title: e.target.value, Type: 0 })
                  }
                />
                <input
                  className={styles.Text}
                  type="text"
                  placeholder="Text"
                  maxLength={10000}
                  onChange={(e) =>
                    setNewPost({ ...newPost, Body: e.target.value })
                  }
                />
                <select
                  className={styles.Community}
                  type="text"
                  placeholder="Community:"
                  onChange={(e) => getCommunityID(e)}
                >
                  <option
                    value={''}
                    onChange={() => {
                      setCommunityFix(newPost['Anime-Sub'])
                    }}
                  >
                    {communityFix}
                  </option>
                  {comsList ? (
                    comsList.map((com) => {
                      return (
                        <option key={uuid()} value={com[0]}>
                          {com[0]}
                        </option>
                      )
                    })
                  ) : (
                    <option></option>
                  )}
                </select>
                <select
                  className={styles.Spoiler}
                  type="text"
                  placeholder="Does this post contain any spoiler content: No."
                  onChange={(e) =>
                    setNewPost({ ...newPost, Spoiler: e.target.value })
                  }
                >
                  <option defaultValue={0} value={0}>
                    --Spoiler--
                  </option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <select
                  className={styles.NSFW}
                  type="text"
                  placeholder="Does this post contain any objectionable content: No."
                  onChange={(e) =>
                    setNewPost({ ...newPost, NSFW: e.target.value })
                  }
                >
                  <option value={0}>--NSFW--</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </form>
            </div>
          ) : //Media Post
          type === 'Media' ? (
            <div className={styles.Variable__Container} data-theme={theme}>
              <form>
                <div className={styles.Media__Container} data-theme={theme}>
                  <div
                    className={styles.Preview}
                    onClick={() => {
                      document.documentElement.scrollTop = 100
                      if (preview.length > 0) {
                        return
                      } else {
                        setIsPreviewOpen(true)
                      }
                    }}
                  >
                    {preview.length === 3 ||
                    preview.length === 0 ||
                    previewIndex === preview.length - 3 ? (
                      <div display={'none'}></div>
                    ) : (
                      <div className={styles.Nav}>
                        <div
                          className={styles.Forward}
                          onClick={() => {
                            if (previewIndex < preview.length - 1) {
                              setPreviewIndex(previewIndex + 3)
                            }
                          }}
                        ></div>
                      </div>
                    )}

                    {preview.length === 0 || previewIndex === 0 ? (
                      <div display={'none'}></div>
                    ) : (
                      <div className={styles.Nav}>
                        <div
                          className={styles.Back}
                          onClick={() => {
                            if (previewIndex > 0) {
                              setPreviewIndex(previewIndex - 3)
                            }
                          }}
                        ></div>
                      </div>
                    )}
                    <div className={styles.Close}>
                      {preview.length > 0 ? (
                        <div
                          className={styles.Trash}
                          onClick={() => {
                            setPreview([])
                            setPreviewIndex(0)
                            setImageForm([
                              {
                                image: '/public/Rekishi-Logo-Purple.png',
                                createdAt: Timestamp.now().toDate(),
                              },
                            ])
                          }}
                        ></div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {preview.length > 0 &&
                    preview[0].startsWith('data:video/') ? (
                      <video
                        controls
                        src={preview[previewIndex]}
                        className={styles.Video__Display}
                      ></video>
                    ) : preview.length > 0 ? (
                      <img
                        className={styles.Image__Display}
                        src={preview[previewIndex]}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <MediaModal />
                  <input
                    type="text"
                    placeholder="Title"
                    className={styles.Media__Title}
                    onChange={(e) =>
                      setNewPost({
                        ...newPost,
                        Title: e.target.value,
                        Body: 'None',
                      })
                    }
                  />
                </div>
                <select
                  className={styles.Community}
                  type="text"
                  placeholder="Community:"
                  onChange={(e) => getCommunityID(e)}
                >
                  <option value={''}>{communityFix}</option>
                  {comsList ? (
                    comsList.map((com) => {
                      return (
                        <option key={uuid()} value={com[0]}>
                          {com[0]}
                        </option>
                      )
                    })
                  ) : (
                    <option></option>
                  )}
                </select>
                <select
                  className={styles.Spoiler}
                  type="text"
                  placeholder="Does this post contain any spoiler content: No."
                  onChange={(e) =>
                    setNewPost({ ...newPost, Spoiler: e.target.value })
                  }
                >
                  <option value={0}>--Spoiler--</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <select
                  className={styles.NSFW}
                  type="text"
                  placeholder="Does this post contain any objectionable content: No."
                  onChange={(e) =>
                    setNewPost({ ...newPost, NSFW: e.target.value })
                  }
                >
                  <option value={0}>--NSFW--</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
              </form>
            </div>
          ) : (
            //URL Post
            <div className={styles.Variable__Container}>
              <form>
                <div className={styles.Url__Container}>
                  <UrlPreview url={url} />
                  <div className={styles.SubUrl__Container}>
                    <input
                      type="text"
                      placeholder="Title"
                      className={styles.Url__Title}
                      onChange={(e) =>
                        setNewPost({
                          ...newPost,
                          Title: e.target.value,
                          Type: 5,
                        })
                      }
                    />
                    <div>
                      <input
                        type="text"
                        placeholder="Url"
                        className={styles.Url__Link}
                        value={url}
                        onChange={handleUrlChange}
                      />
                    </div>
                  </div>
                </div>
                <select
                  className={styles.Community}
                  type="text"
                  placeholder="Community:"
                  onChange={(e) => getCommunityID(e)}
                >
                  <option value={''}>{communityFix}</option>
                  {comsList ? (
                    comsList.map((com) => {
                      return (
                        <option key={uuid()} value={com[0]}>
                          {com[0]}
                        </option>
                      )
                    })
                  ) : (
                    <option></option>
                  )}
                </select>
                <select
                  className={styles.Spoiler}
                  type="text"
                  placeholder="Does this post contain any spoiler content: No."
                  onChange={(e) =>
                    setNewPost({ ...newPost, Spoiler: e.target.value })
                  }
                >
                  <option value={0}>--Spoiler--</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                <div className={styles.Test}>
                  <select
                    className={styles.NSFW}
                    type="text"
                    placeholder="Does this post contain any objectionable content: No."
                    onChange={(e) =>
                      setNewPost({ ...newPost, NSFW: e.target.value })
                    }
                  >
                    <option value={0}>--NSFW--</option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </select>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className={styles.Post__Button__Container}>
        <button
          className={styles.Post}
          onClick={() => {
            postToStorage()
          }}
          data-theme={theme}
          disabled={postButton}
        >
          Post
        </button>
      </div>
    </div>
  )
}

export default Post
