import React from 'react'
import styles from './Rekishi-Premium.module.css'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

function RekishiPremium() {
  const { theme } = UserTheme()
  return (
    <div className={styles.Container} data-theme={theme}>
      <p className={styles.Title}>Rekishi Premium</p>
      <div className={styles.Options_Container}>
        <p className={styles.Info}>
          Rekishi premium is a monthly subscription that removes all ads from
          user feeds, news feeds, and anime community feeds. Rekishi premium
          costs $9.99 a month and is renewed automatically every month.
        </p>
        <p id={styles.premium}>Rekishi premium is: Disabled</p>
        <p id={styles.subscribe}>Subscribe for $4.99/month</p>
      </div>
      <div className={styles.External__Links}>
        <CustomLink to="/privacy-policy" className={styles.Policy}>
          Privacy Policy
        </CustomLink>
        <CustomLink to="/terms-and-conditions" className={styles.Terms}>
          Terms and Conditions
        </CustomLink>
      </div>
    </div>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <Link to={to} {...props} id={isActive ? styles.active : ''}>
      {children}
    </Link>
  )
}

export default RekishiPremium
