import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './SignUp.module.css'
import { UserAuth } from '../../../Contexts/AuthContext'
import { UserTheme } from '../../../Contexts/ThemeContext'

const SignUp = () => {
  //useState Hooks
  const [signUpButton, setSignUpButton] = useState('disabled')
  const [signUpFullName, setSignUpFullName] = useState('')
  const [signUpUsername, setSignUpUsername] = useState('')
  const [signUpEmail, setSignUpEmail] = useState('')
  const [signUpPassword, setSignUpPassword] = useState('')
  const [error, setError] = useState('')
  // console.log(error)

  //Destructured varriabels
  const { createUser, hide } = UserAuth()
  const { theme, SwitchThemeAndLogo, logo } = UserTheme()
  const navigate = useNavigate()

  //Declared Variables
  let signUpDependencies = [
    signUpFullName,
    signUpUsername,
    signUpEmail,
    signUpPassword,
  ]

  //useEffect Hooks
  useEffect(() => {
    let filteredDependencies = signUpDependencies.filter(
      (dependency) => dependency !== ''
    )

    if (filteredDependencies.length === signUpDependencies.length) {
      setSignUpButton('')
    } else {
      setSignUpButton('disabled')
    }
  }, [signUpDependencies])

  useEffect(() => {
    // Check if the user is visiting on a mobile device
    const isMobileDevice = /(iPhone|iPad|iPod|Android)/i.test(
      navigator.userAgent
    )

    // Redirect to the App Store if it's a mobile device
    if (isMobileDevice) {
      window.location.href =
        'https://apps.apple.com/us/app/rekishi/id1593486431'
    }
  }, [])

  //Sign Up Function
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      await createUser(signUpEmail, signUpPassword)
      navigate('/feed')
    } catch (e) {
      setError(e.message)
      // console.log(e.message)
      alert(e.message)
    }
  }

  if (hide)
    return (
      <div className={styles.Login__Wrapper} data-theme={theme}>
        <img
          src={logo}
          alt="logo"
          width="300"
          height="138"
          className={styles.Logo}
          data-theme={theme}
        />
      </div>
    )

  return (
    <div className={styles.SignUp__Wrapper} data-theme={theme}>
      <div>
        <img
          src={logo}
          alt="logo"
          width="300"
          height="138"
          className={styles.Logo}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.SignUp__Form__Inner}>
          <div className={styles.SignUp__Form__Group}>
            <label htmlFor="Full Name"></label>
            <input
              className={styles.SignUp__Form__Inputs}
              type="text"
              name="fullname"
              id="fullname"
              placeholder="Full Name"
              autoComplete="on"
              required
              onChange={(e) => setSignUpFullName(e.target.value)}
            />
          </div>
          <div className={styles.SignUp__Form__Group}>
            <label htmlFor="username"></label>
            <input
              className={styles.SignUp__Form__Inputs}
              type="text"
              name="username"
              id="username"
              placeholder="Username"
              autoComplete="on"
              required
              onChange={(e) => setSignUpUsername(e.target.value)}
            />
          </div>
          <div className={styles.SignUp__Form__Group}>
            <label htmlFor="email"></label>
            <input
              className={styles.SignUp__Form__Inputs}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              autoComplete="on"
              required
              onChange={(e) => setSignUpEmail(e.target.value)}
            />
          </div>
          <div className={styles.SignUp__Form__Group}>
            <label htmlFor="password"></label>
            <input
              className={styles.SignUp__Form__Inputs}
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              autoComplete="on"
              required
              onChange={(e) => setSignUpPassword(e.target.value)}
            />
          </div>
          <div className={styles.SignUp__Form__Group}>
            <input
              className={styles.SignUp__Form__Inputs}
              type="submit"
              value="Sign up"
              id={styles.SignUp__Submit}
              disabled={signUpButton}
            />
          </div>
          <p className={styles.SignUp__Form__Group}>
            <Link to="/">
              {' '}
              <span id={styles.Before__Login__Link}>
                {' '}
                Already have an account?{' '}
              </span>{' '}
              &nbsp; Login
            </Link>
          </p>
        </div>
      </form>
    </div>
  )
}

export default SignUp
