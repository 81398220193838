import React, { useState, useEffect } from 'react';
import styles from './Sidebar.module.css'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../../../../Contexts/AuthContext'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { UserTheme } from '../../../../Contexts/ThemeContext'

function Sidebar() {
  const { user, logout } = UserAuth()
  const { theme, SwitchThemeAndLogo } = UserTheme()
  const navigate = useNavigate()
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
      try {
        await logout();
        navigate('/login');
        // console.log('Successfully logged out');
        // console.log(user);
      } catch (e) {
        // console.log(e.message);
      }
    }
  }
  return (
    <div
      className={`${styles['Navbar']} ${scrolled ? styles.scrolled : ''} ${styles[`${isOpen && 'open'}`]}`}
      data-theme={theme}
    >
      <div
        className={`${styles['Nav__Items']} ${styles[`${isOpen && 'open'}`]}`}
        id="NavItems"
      >
        <CustomLink to="/your-account" id={styles.Your__Account__Link}>
          <div className={styles.YourAccount} id={styles.YourAccount}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>Your Account</p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              See and change information about your account.
            </p>
          </div>
        </CustomLink>
        <CustomLink to="/content">
          <div className={styles.Content}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>Content</p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              Manage what content you want to see.
            </p>
          </div>
        </CustomLink>

        <CustomLink to="/accessibility">
          <div className={styles.ADL}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>
              Accessibility, Display, and Languages
            </p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              Manage how your Rekishi content is displayed.
            </p>
          </div>
        </CustomLink>

        <CustomLink to="/rekishi-premium">
          <div className={styles.RekishiPremium}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>Rekishi Premium</p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              Purchase Rekishi premium to never have ads.
            </p>
          </div>
        </CustomLink>

        <CustomLink to="/purchases">
          <div className={styles.Purchases}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>Purchases</p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              View your Rekishi shop purchases.
            </p>
          </div>
        </CustomLink>

        <CustomLink to="/security">
          <div className={styles.Security}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>Security</p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              Enabling or disabling two-factor authentication.
            </p>
          </div>
        </CustomLink>

        <CustomLink to="/safety-privacy">
          <div className={styles.Safety__Privacy}></div>
          <div className={styles.Link__Container}>
            <p className={styles.Sidebar__Titles}>Privacy and Safety</p>
            <p
              className={`${styles['Sidebar__Descriptions']} ${
                styles[`${isOpen && 'open'}`]
              }`}
            >
              Manage what information you share with Rekishi.
            </p>
          </div>
        </CustomLink>

        <button
          className={`${styles['Logout__Button']} ${
            styles[`${isOpen && 'open'}`]
          }`}
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      <div
        className={`${styles['Nav__Toggle']} ${styles[`${isOpen && 'open'}`]}`}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className={styles.Bar}></div>
      </div>
    </div>
  )

  function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
  
    const closeSidebar = () => {
      setIsOpen(false);
    };
  
    return (
      <Link to={to} {...props} id={isActive ? styles.active : ''} onClick={closeSidebar}>
        {children}
      </Link>
    );
  }
}

export default Sidebar
