import React, { useState, useEffect } from 'react';
import styles from './MediaLanding.module.css';
import BackButton from '../../../../../../Reusable/BackButton/BackButton';
import Episodes from './Episodes/Episodes';
import Sequences from './Sequences/Sequences';
import Manga from './Manga/Manga';
import { ref as dbRef, onValue, get } from 'firebase/database';
import { db } from '../../../../../../../firebase';
import { useParams } from 'react-router-dom';
import { Modals } from '../../../../../../../Contexts/ModalsContext';
import { UserTheme } from '../../../../../../../Contexts/ThemeContext';
import { Medias } from '../../../../../../../Contexts/MediaContext';
import MediaModal from '../../../../../../Reusable/MediaModal/MediaModal'
import { UserAuth } from '../../../../../../../Contexts/AuthContext'
import OptionsModal from '../../Profile/Modals/OptionsModal/OptionsModal'
import EditModal from '../../Profile/Modals/EditModal/EditModal'

function MediaLanding() {
  const { setIsEpisodesOpen, setIsSequencesOpen } = Modals();
  const { theme } = UserTheme();
  const { mediaid } = useParams();
  const [media, setMedia] = useState(null);
  const [manga, setManga] = useState(null);
  const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(null);
  const [selectedSequenceIndex, setSelectedSequenceIndex] = useState(null);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isMediaCommentsOpen, setIsMediaCommentsOpen] = useState(false);
  const { getCommentData2, setIsMediaOpen, mediaIDRef, isMediaOpen, prepPostUrls, revertUrls, getMedia } = Medias();

  const {
    user,
    checkLiked2,
    checkLikedMediaComments
  } = UserAuth()

  const toggleMediaComments = () => {
    setIsMediaOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const ref = dbRef(db, 'the-media-posts/' + mediaid);
      const snapshot = await get(ref);
      const data = snapshot.val();
      setMedia(data);
      setIsLoading(false); // Data is available, set loading to false
      // Call getMedia here after the data is loaded
      getMedia(data);
    };
  
    fetchData();
  }, [mediaid]);

  useEffect(() => {
    if (media) {
      if (media.hasOwnProperty('Manga') === true) {
        setManga(true);
      } else {
        setManga(false);
      }
    }
  }, [media]);

  useEffect(() => {
    if (media) {
      if (isMediaOpen && mediaIDRef.current.MediaID) {
        prepPostUrls(mediaIDRef.current.MediaID);
      } else if (! isMediaOpen) {
        revertUrls('media', mediaIDRef.current.MediaID);
      }
    }
  }, [isMediaOpen, media, mediaIDRef.current]);

  const autoFullscreen = () => {
    // Implement your autoFullscreen logic here
  };

  if (isLoading || media === null) {
    return 'Loading...'; // Render loading state or media not found
  }

  if (!media) {
    return 'Media not found'; // Handle case when media is not found
  }
  const isVideo = media.Video;

  return (
    <div className={styles.Landing__Wrapper} data-theme={theme}>
      <BackButton />
      {isVideo ? (
        Array.from({ length: media.Episodes }, (_, index) => (
          <React.Fragment key={index}>
            <MediaModal
              posts={[media]}
              index={index}
              numPhotos={1}
            />
            <OptionsModal postIndex={index} />
            <EditModal postIndex={index} />
          </React.Fragment>
        ))
      ) : (
        <React.Fragment>
          <MediaModal
            posts={[media]}
            index={selectedSequenceIndex}
            numPhotos={1}
          />
          <OptionsModal postIndex={selectedSequenceIndex} />
          <EditModal postIndex={selectedSequenceIndex} />
        </React.Fragment>
      )}
      <div className={styles.Banner}>
        <img className={styles.Landing__Image} src={media.Banner} />
      </div>
      <div className={styles.Meta}>
        <div className={styles.Title}>{media.Title}</div>
        <div className={styles.Author}>By: {media.Author}</div>
        <div className={styles.Summary}>{media.About}</div>
        {isVideo ? (
          <div className={styles.Views}>Views: {media.Views}</div>
        ) : (
          <div className={styles.Views}>
            Issue: {media.Issue} Sequences: {media.Sequences}
          </div>
        )}
      </div>
      <div className={styles.Episodes__Sequences__Container}>
        <div className={isVideo ? styles.Episodes : styles.Sequences}>
          {Array.from({ length: isVideo ? media.Episodes : media.Volumes }, (_, index) => (
            <div key={index} className={isVideo ? styles.Episode : styles.Sequence}>
              <div className={styles.TextAndCommentContainer}>
                {isVideo ? (
                  <div className={styles.Text}>{`Episode ${index + 1}: ${media[`Episodes${index + 1}`]}`}</div>
                ) : (
                  <div className={styles.Text}>
                    {media.Manga ? `Manga: #${index + 1} ` : `Sequence: #${index + 1}`}
                  </div>
                )}
                <div className={styles.CommentButton}>
                  {/* Add the comment image here */}
                  <img
                    src="/Rekishi_Icons/message@2x2.png"
                    className={styles.Icons}
                    id={styles.MessageIcon}
                    onClick={(event) => {
                      event.stopPropagation();
                      checkLiked2(user.uid, media.MediaID)
                      checkLikedMediaComments(user.uid, media.MediaID)
                      getCommentData2(media.MediaID)
                      toggleMediaComments();
                    }}
                  />
                </div>
              </div>
              {/* {isMediaCommentsOpen && <MediaComments />} */}
            </div>
          ))}
        </div>
      </div>
      {/* Conditionally render the Episodes or Sequences component */}
      {selectedEpisodeIndex !== null && isVideo && (
        <div>
          <Episodes mediaID={media.MediaID} numViews={media.Views} episodeIndex={selectedEpisodeIndex} />
        </div>
      )}
      {selectedSequenceIndex !== null && !isVideo && (
        media.Manga ? (
          <Manga mediaID={media.MediaID} sequenceIndex={selectedSequenceIndex} />
        ) : (
          <Sequences mediaID={media.MediaID} sequenceIndex={selectedSequenceIndex} />
        )
      )}
    </div>
  );
}

export default MediaLanding;
