import React, { useState, useEffect, useRef } from 'react'
import styles from './Community.module.css'
import BackButton from '../../../../../../Reusable/BackButton/BackButton'
import PostType0 from '../../../../../../Reusable/PostTypes/PostType0'
import PostType1 from '../../../../../../Reusable/PostTypes/PostType1'
import PostType2 from '../../../../../../Reusable/PostTypes/PostType2'
import PostType5 from '../../../../../../Reusable/PostTypes/PostType5'
import Post from '../../Post//Post'
import PostModal from '../../../../../../Reusable/PostModal/PostModal'
import EditModal from '../../Profile/Modals/EditModal/EditModal'
import OptionsModal from '../../Profile/Modals/OptionsModal/OptionsModal'
import uuid from 'react-uuid'
import { ref as dbRef, onValue, update } from 'firebase/database'
import { db } from '../../../../../../../firebase'
import { Link, useParams } from 'react-router-dom'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'
import { UserAuth } from '../../../../../../../Contexts/AuthContext'
import { Posts } from '../../../../../../../Contexts/PostsContext'

function Community() {
  const { theme } = UserTheme()
  const {
    user,
    numPhotos,
    checkNumPhotos,
    getCommentIDs,
    checkLiked,
    convertTS,
  } = UserAuth()
  const {
    getPosts,
    posts,
    getCommentData,
    isPostOpen,
    setIsPostOpen,
    prepPostUrl,
    revertUrl,
  } = Posts()
  const [community, setCommunity] = useState()
  const [animeFeed, setAnimeFeed] = useState([])
  const [members, setMembers] = useState()
  const [postIndex, setPostIndex] = useState()
  const [buttonSwitch, setButtonSwitch] = useState(true)
  const { communityid } = useParams()
  const postRef = useRef()

  const getCommunity = (ID) => {
    onValue(dbRef(db, 'anime-communities/' + ID), (snapshot) => {
      const data = snapshot.val()
      setCommunity(data)
    })
  }

  const getAnimeFeed = (community) => {
    onValue(dbRef(db, 'anime-feed/' + community), (snapshot) => {
      const data = Object.keys(snapshot.val())
      setAnimeFeed(data)
    })
  }

  const getAnimeFollowers = (community) => {
    onValue(dbRef(db, 'anime-followers/' + community), (snapshot) => {
      const data = Object.keys(snapshot.val())
      setMembers(data)
    })
  }

  const followCommunity = (community) => {
    update(dbRef(db, 'anime-followers/' + community), {
      [user.uid]: 1,
    })

    update(dbRef(db, 'user-following/' + user.uid), {
      [community]: 1,
    })
  }

  const unfollowCommunity = (community) => {
    update(dbRef(db, 'anime-followers/' + community), {
      [user.uid]: null,
    })

    update(dbRef(db, 'user-following/' + user.uid), {
      [community]: null,
    })
  }

  useEffect(() => {
    if (members) {
      if (members.includes(user.uid)) {
        setButtonSwitch(false)
      } else {
        setButtonSwitch(true)
      }
    }
  }, [members])

  useEffect(() => {
    getCommunity(communityid)
  }, [])

  useEffect(() => {
    if (community) {
      getAnimeFeed(community.Community)
      getAnimeFollowers(community.Community)
    }
  }, [community])

  useEffect(() => {
    getPosts(animeFeed)
  }, [animeFeed])

  useEffect(() => {
    if (isPostOpen) prepPostUrl(postRef.current.id)
    else if (!isPostOpen) {
      revertUrl('community', communityid)
    }
  }, [isPostOpen])

  if (!community) return 'Loading...'

  return (
    <div className={styles.Communities__Wrapper} data-theme={theme}>
      <BackButton />
      <div className={styles.Banner}>
        <div className={styles.Com__Info}>
          <div className={styles.Image__Container}>
            <img
              src={community.Image}
              alt="profile"
              className={styles.Community__Image}
            />
          </div>
          <div className={styles.Meta}>
            <div className={styles.Com__Name}>{community.Community}</div>
            <div className={styles.Stats__Container}>
              <div className={styles.Posts}>
                <span id={styles.posts}>{animeFeed.length}</span>
                <div>Posts</div>
              </div>
              <div className={styles.Members}>
                <span id={styles.members}>
                  {members ? members.length : 'Loading...'}
                </span>
                <div>Members</div>
              </div>
            </div>
            {!buttonSwitch ? (
              <button
                className={styles.Unfollow}
                onClick={() => unfollowCommunity(community.Community)}
              >
                Unfollow
              </button>
            ) : (
              <button
                className={styles.Follow}
                onClick={() => followCommunity(community.Community)}
              >
                Follow
              </button>
            )}
            <div className={styles.About}>{community.About}</div>
          </div>
        </div>
      </div>
      <Post postIndex={postIndex} />
      <PostModal
        posts={posts}
        index={postIndex}
        setPostIndex={setPostIndex}
        checkNumPhotos={checkNumPhotos}
        numPhotos={numPhotos}
      />
      <OptionsModal postIndex={postIndex} />
      <EditModal postIndex={postIndex} />
      <div className={styles.Com__Posts}>
        {posts.reverse().map((post, index) =>
          !post ? (
            <div className={styles.null}></div>
          ) : post.Type === 1 ? (
            <Link
              ref={postRef}
              id={post.PostID}
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType1 post={post} index={index} />
            </Link>
          ) : post.Type === 2 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType2 post={post} index={index} />
            </Link>
          ) : post.Type === 0 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType0 post={post} index={index} />
            </Link>
          ) : post.Type === 5 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType5 post={post} index={index} key={uuid()} />
            </Link>
          ) : null
        )}
      </div>
    </div>
  )
}

export default Community
