import React, { useEffect } from 'react';
import styles from './Comments.module.css';
import uuid from 'react-uuid';
import { UserAuth } from '../../../../Contexts/AuthContext';
import { Modals } from '../../../../Contexts/ModalsContext';
import { UserTheme } from '../../../../Contexts/ThemeContext';
import { Medias } from '../../../../Contexts/MediaContext';

function Comments({}) {
  const {
    convertCommentTS,
    commentTSArray,
    setLikedComments2, // Corrected function name
    checkLikedMediaComments,
    likeComment2,
    likedComments2,
    setCommentOption,
  } = UserAuth();
  const { theme } = UserTheme();
  const { setIsOptionsOpen } = Modals();
  const { comments, commenters } = Medias();

  useEffect(() => {
    convertCommentTS();
  }, [comments]);

  const test = (comment) => {
    setCommentOption(comment)
    setIsOptionsOpen(true)
  }

  useEffect(() => {
    const updateLikedComments = async () => {
      const updatedLikedComments = await Promise.all(
        comments.map(async (comment, index) => {
          return await checkLikedMediaComments(comment[1]);
        })
      );
      likedComments2 && setLikedComments2(updatedLikedComments);
    };
  
    updateLikedComments();
  }, [comments, likedComments2, checkLikedMediaComments]);

  return (
    <div data-theme={theme}>
      {commenters.length !== comments.length
        ? ''
        : comments.map((comment, index) => {
            return (
              <div key={uuid()}>
                <div className={styles.Container}>
                  <div className={styles.Commenter__Container}>
                    <img
                      className={styles.Profile}
                      src={commenters[index].profileImageUrl}
                      loading="lazy"
                    ></img>
                    <div className={styles.Username}>
                      {commenters[index].username}
                    </div>
                  </div>
                  <div className={styles.Comment__Container}>
                    <div className={styles.Comment}>{comment[1].Comment}</div>
                    <div className={styles.Stack}>
                      {comment.User === 'AoJ31bgIMdMY5blzzwu6ypOSNXa2' ? (
                        <div></div>
                      ) : (
                        <div className={styles.Likes__Container}>
                          <div className={styles.Likes}>
                            {!comment[1].Likes ? (
                              <div className={styles.Likes}>{0}</div>
                            ) : (
                              <div className={styles.Likes}>
                                {comment[1].Likes}
                              </div>
                            )}
                          </div>
                          <div
                            className={`${styles['Like']} ${
                              styles[`${likedComments2[index] && 'liked'}`]
                            }`}
                            onClick={() => {
                              likeComment2(comment[1], index);
                            }}
                          ></div>
                        </div>
                      )}
                      <div className={styles.Meta__Container}>
                        <div className={styles.Weeks}>
                          {(() => {
                            switch (true) {
                              //months
                              case commentTSArray[index] > 604800:
                                return (
                                  Math.round(commentTSArray[index] / 604800) +
                                  'w'
                                );
                              //weeks
                              case commentTSArray[index] > 86400:
                                return (
                                  Math.round(commentTSArray[index] / 86400) +
                                  'd'
                                );
                              //days
                              case commentTSArray[index] > 3600:
                                return (
                                  Math.round(commentTSArray[index] / 3600) + 'h'
                                );
                              //minutes
                              case commentTSArray[index] > 60:
                                return (
                                  Math.round(commentTSArray[index] / 60) + 'm'
                                );
                              //seconds
                              case commentTSArray[index] < 60:
                                return (
                                  Math.round(commentTSArray[index]) + 's'
                                );
                              default:
                                return commentTSArray[index];
                            }
                          })()}
                        </div>
                        {comment.User === 'AoJ31bgIMdMY5blzzwu6ypOSNXa2' ? (
                          <div></div>
                        ) : (
                          <div
                            className={styles.Options}
                            onClick={() => test(comment)}
                          >
                            . . .
                          </div>
                        )}
                        {/* <div className={styles.Reply}>Reply</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
}

export default Comments;
