import React from 'react'
import styles from './MediaItems.module.css'
import uuid from 'react-uuid'
import LikeIcon from '../../../../../../Reusable/LikeIcon/LikeIcon'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { UserAuth } from '../../../../../../../Contexts/AuthContext'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'

function MediaItems({ media, index, sortedMedia }) {
  const { theme } = UserTheme()
  const { episodes, getEpisodes } = UserAuth();

  const handleGetEpisodes = () => {
    getEpisodes(media[9]);
  };

  return (
    <div data-theme={theme}>
      {index > 0 &&
      media[0].charAt(0) === sortedMedia[index - 1][0].charAt(0) ? (
        <div></div>
      ) : (
        <div className={styles.Alphabetical}>{media[0].charAt(0)}</div>
      )}
      <div className={styles.Media__Item}>
        <CustomLink
          className={styles.Media__Container}
          to={'/media/' + media[9]}
          key={uuid()}
          onClick={handleGetEpisodes}
        >
          <img className={styles.Image} src={media[5]} />

          <div className={styles.Media}>
            <span>{media[0]}</span>
            {media[13] ? (
              <>
                <span id={styles.views}>Views: {media[15]}</span>
                <span id={styles.episodes}>Episodes: {media[16]}</span>
              </>
            ) : (
              <>
                <span id={styles.views}>Issues: {media[14]}</span>
                <span id={styles.episodes}>Sequences: {media[12]}</span>
              </>
            )}
          </div>
          <div className={styles.About}>{media[1]}</div>
        </CustomLink>
        <div className={styles.Likes}>
          {media[7]}
          <LikeIcon post={media} />
        </div>
      </div>
    </div>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <Link to={to} {...props} id={isActive ? styles.active : ''}>
      {children}
    </Link>
  )
}

export default MediaItems
