import React, { useRef, useState, useEffect } from 'react'
import styles from './MediaModal.module.css'
import ReactDOM from 'react-dom'
import { UserAuth } from '../../../../../../../Contexts/AuthContext'
import { Modals } from '../../../../../../../Contexts/ModalsContext'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'

function MediaModal() {
  const { isPreviewOpen, setIsPreviewOpen } = Modals()
  const { setImageForm, preview, setPreview, newPost, setNewPost } = UserAuth()
  const { theme } = UserTheme()
  const fileInputRef = useRef()
  const [images, setImages] = useState()
  const [file, setFile] = useState()
  const [previewIndex, setPreviewIndex] = useState(0)

  useEffect(() => {
    setPreview([])

    if (images) {
      for (let i = 0; i < images.length; i++) {
        // console.log('ran ' + i)
        const reader = new FileReader()

        reader.onload = function () {
          const img = new Image()
          const vid = document.createElement('video')
          img.src = reader.result
          vid.src = reader.result
          const isGif = img.src.startsWith('data:image/gif')
          const isImage = img.src.startsWith('data:image/')
          const isVideo = img.src.startsWith('data:video/')

          if (isGif) {
            setNewPost({ ...newPost, Type: 4 })
          } else if (isImage) {
            setNewPost({ ...newPost, Type: 1 })
          } else if (isVideo) {
            setNewPost({ ...newPost, Type: 2 })
          } else {
            // console.log('Selected file is not an image, gif, or a video.')
          }

          img.onload = function () {
            setPreview((preview) => [
              ...preview,
              img.src,
              `ImageHeight: ${img.naturalHeight}`,
              `ImageWidth: ${img.naturalWidth}`,
            ])
          }

          vid.onloadeddata = function () {
            setPreview((preview) => [
              ...preview,
              vid.src,
              `ImageHeight: ${img.naturalHeight}`,
              `ImageWidth: ${img.naturalWidth}`,
            ])
          }
        }

        reader.readAsDataURL(images[i])
      }
    } else {
      setPreview([])
    }
  }, [images])

  if (!isPreviewOpen) return null

  const handlePostImages = (e) => {
    const chosenFiles = e.target.files

    // console.log(chosenFiles, 'files check')

    if (chosenFiles.length < 5) {
      setImages(chosenFiles)
    } else {
      e.target.value = null // Clear the selected files
      alert('You can select up to 5 files only.')
    }

    // console.log(chosenFiles, 'chosenFiles')

    for (let i = 0; i < e.target.files.length; i++) {
      setImageForm((imageForm) => [...imageForm, { image: e.target.files[i] }])
    }
  }
  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        data-theme={theme}
        onClick={() => setIsPreviewOpen(false)}
      />
      <div className={styles.Modal} data-theme={theme}>
        <div
          className={styles.Close}
          onClick={() => {
            setIsPreviewOpen(false)
          }}
        >
          <div className={styles.Bar}></div>
        </div>
        {preview.length === 0 ? (
          <img
            src={null}
            height={500}
            width={400}
            className={styles.Image__Preview}
          />
        ) : (
          <div>
            {preview.length === 0 || preview.length - 3 === previewIndex ? (
              <div display={'none'}></div>
            ) : (
              <div
                className={styles.Nav}
                id={styles.Forward}
                onClick={() => {
                  if (
                    previewIndex < preview.length - 1 &&
                    previewIndex % 3 === 0
                  ) {
                    setPreviewIndex(previewIndex + 3)
                  }
                }}
              ></div>
            )}
            {preview[0].startsWith('data:video/') ? (
              <video
                controls
                src={preview[previewIndex]}
                className={styles.Video__Display}
              ></video>
            ) : (
              <img
                src={preview[previewIndex]}
                className={styles.Image__Display}
              />
            )}

            {preview.length === 0 || previewIndex === 0 ? (
              <div display={'none'}></div>
            ) : (
              <div
                className={styles.Nav}
                id={styles.Back}
                onClick={() => {
                  if (previewIndex > 0 && previewIndex % 3 === 0) {
                    setPreviewIndex(previewIndex - 3)
                  }
                }}
              ></div>
            )}
          </div>
        )}
        <button
          className={styles.Select__Images}
          onClick={(e) => {
            e.preventDefault()
            fileInputRef.current.click()
          }}
        >
          Select Images
        </button>
        <input
          type="file"
          multiple="multiple"
          name="image"
          accept="image/*, video/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          value={file}
          className={styles.Choose__File}
          onChange={(e) => handlePostImages(e)}
        />
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default MediaModal
