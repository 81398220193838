import React, { createContext, useContext, useState } from 'react';

const SearchContext3 = createContext();

export function SearchProvider3({ children }) {
  const [search3, setSearch3] = useState('');
  const [placeholderText3, setPlaceholderText3] = useState('');

  return (
    <SearchContext3.Provider value={{ search3, setSearch3, placeholderText3, setPlaceholderText3 }}>
      {children}
    </SearchContext3.Provider>
  );
}

export function useSearch3() {
  return useContext(SearchContext3);
}