import React, { useState, useEffect } from 'react'
import styles from './PostModal.module.css'
import ReactDOM from 'react-dom'
import Comments from './Comments/Comments'
import { UserAuth } from '../../../Contexts/AuthContext'
import { UserTheme } from '../../../Contexts/ThemeContext'
import { Posts } from '../../../Contexts/PostsContext'


function PostModal({ posts, index, setPostIndex, checkNumPhotos, numPhotos }) {
  const { theme, setIconTheme, iconTheme } = UserTheme()
  const {
    user,
    getUserPosts,
    getCommunity,
    date,
    getComFeedComments,
    checkLiked,
    checkLikedComments,
    liked3,
    getCommentIDs,
    liked,
    likePost,
    postComment,
  } = UserAuth()

  // getComFeedComments(posts, index + 1)
  // checkLikedComments(posts, index, posts[index].Likes)
  // checkLikedComments()

  const {
    getCommentData,
    clearCommentsData,
    comments,
    commenters,
    isPostOpen,
    setIsPostOpen,
  } = Posts()

  const [newComment, setNewComment] = useState()
  const [postButton, setPostButton] = useState(true)
  const [photoNav, setPhotoNav] = useState(0)
  const [videoDisplay, setVideoDisplay] = useState(false)

  useEffect(() => {
    setIconTheme(theme)
  }, [theme])

  useEffect(() => {
    if (!newComment) {
      setPostButton(true)
    } else {
      setPostButton(false)
    }
  }, [newComment])

  if (!isPostOpen) return null
  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        data-theme={theme}
        onClick={() => {
          getUserPosts()
          setIsPostOpen(false)
          setVideoDisplay(false)
          setPhotoNav(0)
          clearCommentsData()
        }}
      />
      {index !== 0 ? (
        <div
          className={styles.Nav__Outer}
          data-theme={theme}
          id={styles.Back}
          onClick={() => {
            if (index > 0) {
              getComFeedComments(posts, index - 1)
              checkLiked(user.uid, posts[index - 1].PostID)
              getCommentIDs(posts[index - 1].PostID)
              setPostIndex(index - 1)
              setPhotoNav(0)
              checkNumPhotos(posts[index - 1])
              getCommentData(posts[index - 1].PostID)
            }
          }}
        ></div>
      ) : (
        <div style={{ display: 'none' }}></div>
      )}

      {index !== posts.length - 1 ? (
        <div
          className={styles.Nav__Outer}
          data-theme={theme}
          id={styles.Forward}
          onClick={() => {
            if (index < posts.length - 1) {
              getComFeedComments(posts, index + 1)
              checkLiked(user.uid, posts[index + 1].PostID)
              getCommentIDs(posts[index + 1].PostID)
              setPostIndex(index + 1)
              setPhotoNav(0)
              checkNumPhotos(posts[index + 1])
              getCommentData(posts[index + 1].PostID)
            }
          }}
        ></div>
      ) : (
        <div style={{ display: 'none' }}></div>
      )}
      <div className={styles.Modal__Container}>
        <div className={styles.Modal}>
          <div className={styles.Title} data-theme={theme}>
            {posts[index].Title}{' '}
            <div
              className={styles.Close}
              onClick={() => {
                setIsPostOpen(false)
                getUserPosts()
                clearCommentsData()
              }}
            >
              <div className={styles.Bar}></div>
            </div>
          </div>
          <div className={styles.Menu} data-theme={theme}>
            {photoNav ? (
              <div
                className={styles.Nav}
                id={styles.Back}
                onClick={() => {
                  if (photoNav > 0) {
                    setPhotoNav(photoNav - 1)
                  }
                }}
              ></div>
            ) : (
              <div style={{ display: 'none' }}></div>
            )}

            {posts[index].Type === 5 ? (
              <img
                src={getCommunity(posts[index]['Anime-Sub'])}
                alt="url"
                className={styles.Image}
                data-theme={theme}
              />
            ) : posts[index].Type === 2 ? (
              !videoDisplay ? (
                <div className={styles.Content__Container}>
                  <img
                    src={posts[index].Thumbnail}
                    alt="url"
                    className={styles.Image__Vid}
                  />
                  <div
                    className={styles.Play}
                    onClick={() => setVideoDisplay(true)}
                  ></div>
                </div>
              ) : (
                <video
                  controls
                  src={posts[index].Image}
                  autoPlay={true}
                  type={'video/mp4'}
                  className={styles.Video}
                />
              )
            ) : posts[index].Type === 0 ? (
              <div className={styles.Text}>{posts[index].Body}</div>
            ) : posts[index].Image2 ? (
              <img src={numPhotos[photoNav]} className={styles.Image} />
            ) : (
              <img src={posts[index].Image} className={styles.Image} />
            )}
            {numPhotos.length <= 1 ? (
              <div style={{ display: 'none' }}></div>
            ) : (
              <div
                className={styles.Nav}
                id={styles.Inner__Forward}
                onClick={() => {
                  if (photoNav < numPhotos.length - 1) {
                    setPhotoNav(photoNav + 1)
                  }
                }}
              ></div>
            )}

            <div className={styles.Engage}>
              <div className={styles.Comment__Section} data-theme={theme}>
                {comments && commenters ? (
                  <Comments postIndex={index} />
                ) : (
                  <div></div>
                )}
              </div>
              <div className={styles.Like__Display} data-theme={theme}>
                <button
                  className={`${styles['Like__Icon']} ${
                    styles[`${liked && 'liked'}`]
                  } ${styles[`${iconTheme}`]}`}
                  onClick={() => {
                    likePost(posts, index, posts[index].Likes)
                  }}
                ></button>
                <button
                  className={`${styles['Comment__Icon']} ${
                    styles[`${iconTheme}`]
                  }`}
                  // onClick={() =>
                  //   console.log(document.getElementById('commentField').focus())
                  // }
                ></button>
                <div className={styles.Post__Date}>{date}</div>
                <div className={styles.Anime__Sub}>
                  {posts[index]['Anime-Sub']}
                </div>
              </div>
              <div className={styles.Comment} data-theme={theme}>
                <div
                  className={`${styles['Comment__Icon']} ${
                    styles[`${iconTheme}`]
                  }`}
                  id={styles.Make__Comment__Icon}
                ></div>
                <input
                  className={styles.Comment__Input}
                  data-theme={theme}
                  id="commentField"
                  placeholder="Add a comment"
                  onChange={(e) => setNewComment(e.target.value)}
                />
                <button
                  className={styles.Post__Comment}
                  id={'post'}
                  disabled={postButton}
                  onClick={() => {
                    postComment(
                      newComment,
                      posts[index].PostID,
                      user.uid,
                      posts[index]['Anime-Sub']
                    )
                  }}
                >
                  Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default PostModal
