import React from 'react'
import styles from './AllPostTypes.module.css'
import LikeIcon from '../LikeIcon/LikeIcon'

function PostType2({ post, index }) {
  return (
    <div className={styles.Overlay}>
      <div className={styles.Post}>
        <video className={styles.Image} src={post.Image} alt={``}></video>
        <div className={styles.Likes__Comments}>
          <LikeIcon post={post} />
          {post.Likes}
          <img
            src={'/Rekishi_Icons/message@2x.png'}
            className={styles.Icons}
            id={styles.MessageIcon}
          />{' '}
          {post.Comments}
        </div>
      </div>
    </div>
  )
}

export default PostType2
