import { createContext, useContext, useState, useRef } from 'react'
import { db } from '../firebase'
import { ref as dbRef, get, onValue } from 'firebase/database'

const PostsContext = createContext()

export const PostsContextProvider = ({ children }) => {
  /*1*/ const [isPostOpen, setIsPostOpen] = useState(false)
  /*2*/ const [comments, setComments] = useState()
  /*3*/ const [commenters, setCommenters] = useState([])
  /*4*/ const [posts, setPosts] = useState([])
  const postRef = useRef()

  /* Gets all of a users or communities posts */
  const getPosts = (postIDs) => {
    setPosts([])
    // console.log(postIDs, 'postIDs')
    // console.log(postIDs.length, 'length')
    for (let i = postIDs.length - 1; i > -1; i--) {
      onValue(dbRef(db, 'the-posts/' + postIDs[i]), async (snapshot) => {
        const data = await snapshot.val()
        setPosts((posts) => [...posts, data])
      })
    }
  }

  const getPosts2 = async (postIDs, page, postsPerPage) => {
    const startIndex = (page - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;
    const postIDsToFetch = postIDs.slice(startIndex, endIndex);
  
    const fetchPost = async (postID) => {
      const snapshot = await get(dbRef(db, 'the-posts/' + postID));
      return snapshot.val();
    };
  
    try {
      const postPromises = postIDsToFetch.map((postID) => fetchPost(postID));
      const fetchedPosts = await Promise.all(postPromises);
  
      // Use setPosts to update the state in the context
      setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  /* Returns comments in an array from newest to oldest. */
  const getCommentData = async (ID) => {
    // console.log(ID, 'ID')
    setComments()
    setCommenters([])
    /* Gets corresponding Username and Photo for each comment */
    const getUsernameAndPhoto = (ID) => {
      const ref = dbRef(db, 'users/' + ID)
      onValue(ref, async (snapshot) => {
        const data = await snapshot.val()
        setCommenters((commenters) => [...commenters, data])
      })
    }

    /* Gets Comment, Date, and Likes */
    const ref = dbRef(db, 'comments/' + ID)
    onValue(ref, (snapshot) => {
      const data = Object.entries(snapshot.val())
      setComments(data)
      data.forEach((comment) => {
        getUsernameAndPhoto(comment[1].User)
      })
    })
  }

  /* Preps the browser URL to navigate to the posts page on 
     refresh when post modal is opened*/
  const prepPostUrl = (ID) => {
    // console.log(ID, 'ID')
    window.history.replaceState(100, 'post', '/post/' + ID)
  }

  /* Reverts the browser URL to stay on the current page when 
     post modal is closed */
  const revertUrl = (route, param) => {
    if (param) {
      window.history.replaceState(99, 'param', `/${route}/` + `${param}`)
    } else {
      window.history.replaceState(98, 'route', `/${route}`)
    }
  }

  /* Clears comment data*/
  const clearCommentsData = () => {
    setComments()
    setCommenters([])
  }

  return (
    <PostsContext.Provider
      value={{
        getCommentData,
        clearCommentsData,
        comments,
        commenters,
        getPosts,
        getPosts2,
        posts,
        setPosts,
        isPostOpen,
        setIsPostOpen,
        postRef,
        prepPostUrl,
        revertUrl,
      }}
    >
      {children}
    </PostsContext.Provider>
  )
}

export const Posts = () => {
  return useContext(PostsContext)
}
