import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styles from './ProfileModal.module.css'
import { Modals } from '../../../../../../../../Contexts/ModalsContext'
import { UserAuth } from '../../../../../../../../Contexts/AuthContext'
import { UserTheme } from '../../../../../../../../Contexts/ThemeContext'

function ProfileModal() {
  const [file, setFile] = useState()
  const [image, setImage] = useState()
  const [preview, setPreview] = useState()
  const [uploadButton, setUploadButton] = useState(true)
  const fileInputRef = useRef()
  const {
    getUserData,
    userObj,
    listStorage,
    deleteImage,
    saveImage,
    setImageForm,
  } = UserAuth()
  const { theme } = UserTheme()
  const { isProfileOpen, setIsProfileOpen } = Modals()

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      // console.log(reader)
      reader.onloadend = () => {
        setPreview(reader.result)
        setUploadButton(false)
      }
      reader.readAsDataURL(image)
    } else {
      setPreview(null)
    }
  }, [image])

  useEffect(() => {
    if (!isProfileOpen) {
      setPreview(null)
    }
  }, [isProfileOpen])

  useEffect(() => {
    if (isProfileOpen) {
      setIsProfileOpen(false)
    }
  }, [userObj.profileImageUrl])

  if (!isProfileOpen) return null

  const handleProfilePicChange = (e) => {
    const chosenFile = e.target.files[0]
    if (chosenFile && chosenFile.type.substr(0, 5) === 'image') {
      setImage(chosenFile)
    } else {
      setImage(null)
    }

    setImageForm((imageForm) => [...imageForm, { image: e.target.files[0] }])
    listStorage(1)
  }

  const handleUploadProfileImage = () => {
    deleteImage()
    saveImage(1)
  }

  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        onClick={() => setIsProfileOpen(false)}
        data-theme={theme}
      />
      <div className={styles.Modal} data-theme={theme}>
        <div className={styles.Header}>Upload profile picture</div>
        <div
          className={styles.Close}
          onClick={() => {
            setIsProfileOpen(false)
          }}
        >
          <div className={styles.Bar}></div>
        </div>
        <div className={styles.Menu}>
          <img
            src={!preview ? userObj.profileImageUrl : preview}
            height={300}
            width={300}
            className={styles.Circle__Profile}
          />

          <label htmlFor="" style={{ display: 'none' }}>
            Image
          </label>
          <button
            className={styles.Choose__Button}
            onClick={(e) => {
              e.preventDefault()
              fileInputRef.current.click()
            }}
          >
            Select Image
          </button>
          <input
            type="file"
            name="image"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            value={file}
            className={styles.Choose__File}
            onChange={(e) => handleProfilePicChange(e)}
          />

          <button
            className={styles.Upload}
            disabled={uploadButton}
            onClick={handleUploadProfileImage}
          >
            Save
          </button>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default ProfileModal
