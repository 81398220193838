import { createContext, useContext, useState, useEffect } from 'react'
import useLocalStorage from 'use-local-storage'
import { toast } from 'react-toastify'

const CartContext = createContext()
export const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useLocalStorage('item', [])
  const [itemQuantities, setItemQuantities] = useLocalStorage('quanitity', [])
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [subtotal, setSubtotal] = useState(0)

  const removeToast = () => {
    toast.success('Item successfully removed from cart!')
  }

  const handleRemoveItem = (index) => {
    const updatedQuantities = [...itemQuantities]
    updatedQuantities.splice(index, 1)
    setItemQuantities(updatedQuantities)

    const tempArr = [...cart]
    tempArr.splice(index, 1)
    setCart(tempArr)
    removeToast()
  }

  const reduceQuantity = (item, index) => {
    const updatedQuantities = [...itemQuantities]
    if (updatedQuantities[index][item.ShopPostID] > 1) {
      updatedQuantities[index][item.ShopPostID] -= 1
      setItemQuantities(updatedQuantities)
    }
  }

  const addQuantity = (item, index) => {
    const updatedQuantities = [...itemQuantities]
    if (updatedQuantities[index][item.ShopPostID] + 1 <= item.Quantity) {
      updatedQuantities[index][item.ShopPostID] += 1
      setItemQuantities(updatedQuantities)
    }
  }

  const calculateSubtotal = () => {
    const tempSubtotal = cart.reduce((subtotal, cartItem) => {
      const quantity = itemQuantities.find((item) => item[cartItem.ShopPostID])
      return (
        subtotal +
        (quantity ? Number(cartItem.Price) * quantity[cartItem.ShopPostID] : 0)
      )
    }, 0)

    setSubtotal(tempSubtotal.toFixed(2))
  }

  useEffect(() => {
    if (cart.length > 0) calculateSubtotal()
  }, [cart, addQuantity, reduceQuantity])

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        itemQuantities,
        setItemQuantities,
        reduceQuantity,
        addQuantity,
        handleRemoveItem,
        removeToast,
        isCartOpen,
        setIsCartOpen,
        subtotal,
        setSubtotal,
        calculateSubtotal,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const UserCart = () => {
  return useContext(CartContext)
}
