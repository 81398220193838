import React from 'react'
import styles from './BackButton.module.css'
import { useNavigate } from 'react-router-dom'

function BackButton() {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
    return true
  }

  return (
    <div className={styles.Container}>
      <div
        className={styles.Back}
        onClick={() => {
          goBack()
        }}
      >
        <span className={styles.Arrow}></span>
        {'Back'}
      </div>
    </div>
  )
}

export default BackButton
