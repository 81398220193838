import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SafetyPrivacy.module.css';
import { UserTheme } from '../../../../../../Contexts/ThemeContext';

function SafetyPrivacy() {
  const { theme } = UserTheme();

  return (
    <div className={styles.Container} data-theme={theme}>
      <p className={styles.Content}>Privacy and Safety</p>
      <div className={styles.Links_Container}>
        <Link to="/terms-and-conditions" className={styles.Links} id={styles.Objectionable}>
          <div className={styles.Link_Stack}>Terms and Conditions</div>
          <span className={styles.Arrow}></span>
        </Link>
        <Link to="/privacy-policy" className={styles.Links}>
          <div className={styles.Link_Stack}>Privacy Policy</div>
          <span className={styles.Arrow}></span>
        </Link>
        <Link to="/user-agreement" className={styles.Links}>
          <div className={styles.Link_Stack}>User Agreement</div>
          <span className={styles.Arrow}></span>
        </Link>
      </div>
    </div>
  );
}

export default SafetyPrivacy;