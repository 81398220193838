import { createContext, useContext, useEffect, useState, useRef } from 'react'
import { ref as dbRef, onValue, update, child, push, get } from 'firebase/database'
import { useNavigate, useLocation } from 'react-router-dom'
import { Timestamp } from 'firebase/firestore'
import {
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
} from 'firebase/auth'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from 'firebase/storage'
import dateFormat, { masks } from 'dateformat'
import { auth, db, storage } from '../firebase'

const UserContext = createContext()

export const AuthContextProvider = ({ children }) => {
  //Auth Hooks
  const [user, setUser] = useState({})
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)
  //Anime Communities List
  const [communities, setCommunities] = useState()
  //User Object
  const [userObj, setUserObj] = useState({})
  const [userID, setUserID] = useState('Test')
  //User States
  const [following, setFollowing] = useState(0)
  //Post Objects
  const [postObjects, setPostObjects] = useState([])
  //Post States
  const [liked, setLiked] = useState()
  const [liked2, setLiked2] = useState()
  const [liked3, setLiked3] = useState()
  const [liked4, setLiked4] = useState()
  /*10*/ const [likedComments, setLikedComments] = useState([])
  const [likedComments2, setLikedComments2] = useState([])
  const [likes, setLikes] = useState([])
  const [date, setDate] = useState([])
  //Comments
  /*13*/ const [commentTSArray, setCommentTSArray] = useState([])
  const [comments, setComments] = useState([])
  const [commentIDs, setCommentIDs] = useState([])
  const [commentOption, setCommentOption] = useState()
  const [editedComment, setEditedComment] = useState()
  /*18*/ const [commenters, setCommenters] = useState([''])

  //Banner and Profile Image States
  const [uploadButton, setUploadButton] = useState(true)
  const [imagePath, setImagePath] = useState()
  const [imageForm, setImageForm] = useState([
    {
      image: '/public/Rekishi-Logo-Purple.png',
      createdAt: Timestamp.now().toDate(),
    },
  ])
  /*22*/ const [search, setSearch] = useState('')
  const [animeFeed, setAnimeFeed] = useState()
  const [animeFollowers, setAnimeFollowers] = useState()
  const [comsList, setComsList] = useState()
  const [filteredComs, setFilteredComs] = useState()
  /*27*/ const [community, setCommunity] = useState()
  const [communityPosts, setCommunityPosts] = useState([])
  const [feedPosts, setFeedPosts] = useState([])
  const [numPhotos, setNumPhotos] = useState([])
  const [required, setRequired] = useState(true)
  /*32*/ const [preview, setPreview] = useState([])
  //Media Sate Hooks
  const [mediaPosts, setMediaPosts] = useState()
  const [videoPath, setVideoPath] = useState()
  const [mediaKeywords, setMediaKeywords] = useState()
  const [mediaLikes, setMediaLikes] = useState()
  const [mediaPages, setMediaPages] = useState()
  //Episodes State Hooks
  /*38*/ const [episodes, setEpisodes] = useState([])
  const [follows, setFollows] = useState([])
  const [postersList, setPostersList] = useState([
    {
      ['2FA']: '0',
      EULA: '1',
      NSFW: '0',
      Phone: 'None',
      UserID: 'SUI6SK9A3EXLcm0uLotdxmlnJN43',
      bannerImageUrl:
        'https://firebasestorage.googleapis.com/v0/b/triton-4848e.appspot.com/o/user-profile-images%2FSUI6SK9A3EXLcm0uLotdxmlnJN43%2F1669237814055%5Bobject%20File%5D?alt=media&token=e32d3526-461f-4f27-8559-e92d2ecaeb56',
      birthday: 'August 17, 1991',
      email: 'dillon.craw@gmail.com',
      fcmToken:
        'ex3u0yhto0Nbmpm9duMh_S:APA91bGe2DZd5PhYQDycVe8z18qI2OBOCeHcH96xOgR0THVhzr36FuobSGrElemtwFk2RYbYd4MCcnT7ZT_dygNiiwC54t_H2vj7kj3MrTtjR4VXidYjxTprvrNObsDxZWnxQOoeBbpu',
      name: 'Dillon Craw',
      profileDescription: 'Hello world',
      profileImageUrl:
        'https://firebasestorage.googleapis.com/v0/b/triton-4848e.appspot.com/o/user-profile-images%2FSUI6SK9A3EXLcm0uLotdxmlnJN43%2F1669237605408%5Bobject%20File%5D?alt=media&token=c6841496-0ba5-4539-8267-e6da9f06b061',
      username: 'dilly311',
    },
  ])
  const [newPost, setNewPost] = useState({
    ['Anime-Sub']: '--Community--',
    Comments: 0,
    Likes: 0,
    NSFW: 0,
    Spoiler: 0,
    Title: '',
    Type: '',
    // User: user.uid,
  })
  /*42*/ const [imagesAssigned, setImagesAssigned] = useState()
  const [vidsAssigned, setVidsAssigned] = useState()
  const [thumbnailAssigned, setThumbnailAssigned] = useState()
  const [triggerPost, setTriggerPost] = useState(false)

  // temp
  const [hide, setHide] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // console.log(location.pathname)
    if (user && location.pathname === '/') {
      navigate('/feed')
    }
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setPending(false)
    })
  }, [])

  useEffect(() => {
    if (imageForm[0].image !== '') {
      setUploadButton(false)
    } else {
      setUploadButton(true)
    }
  }, [imageForm])

  useEffect(() => {
    getMedia()
    getAnimeCommunity()
    getAnimeFeedAndFollowers()
  }, [])

  useEffect(() => {
    if (triggerPost && imagesAssigned === imageForm.length - 2)
      makePost(newPost)
  }, [triggerPost, imagesAssigned])

  useEffect(() => {
    if (triggerPost && vidsAssigned && thumbnailAssigned) makePost(newPost)
  }, [triggerPost, vidsAssigned, thumbnailAssigned])

  //Declared Functions and Variables
  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const logout = () => {
    return signOut(auth)
  }

  const passwordReset = (email) => {
    return sendPasswordResetEmail(auth, email)
  }

  const emailVerification = () => {
    // console.log('email verification funciton ran')
    return sendEmailVerification(auth.currentUser)
  }
  //
  //Get user Data Snapshot: userName, profile image, banner image
  const getUserData = () => {
    const userRef = dbRef(db, 'users/' + user.uid)
    onValue(userRef, async (snapshot) => {
      const data = await snapshot.val()
      setUserObj((userObj) => data)

      if (
        !data.birthday ||
        !data.profileImageUrl ||
        !data.bannerImageUrl ||
        !data.profileDescription
      ) {
        setRequired(false)
      } else {
        setRequired(true)
      }
    })
  }
  //
  //Get user following
  const getUserFollowing = () => {
    const followRef = dbRef(db, 'user-following/' + user.uid)
    onValue(followRef, (snapshot) => {
      const follow = Object.keys(snapshot.val())
      setFollowing(follow.length)
      setFollows(follow)
    })
  }
  //
  //Get post likes
  const getPostLikes = (postID) => {
    const likesRef = dbRef(db, '/post-like/' + postID)
    onValue(likesRef, (snapshot) => {
      const LikesArr = snapshot.val()
      setLikes(LikesArr)
    })
  }

  //
  //Convert Timestamp to Date
  const convertTS = (ts) => {
    const now = new Date(ts * 1000)
    masks.posts = 'mmmm d yyyy'
    setDate(dateFormat(now, 'posts'))
  }
  //
  //
  const checkNumPhotos = (post) => {
    const entries = Object.entries(post)
    const photosArr = entries.filter(
      (entry) => entry[0].includes('Image') && entry[0].length <= 6
    )
    photosArr.forEach((entry) => entry.shift())
    setNumPhotos(photosArr)
  }

  //
  //Get user posts array and content of user posts
  const getUserPosts = () => {
    //Get unique user posts IDs
    setPostObjects([])
    const postsRef = dbRef(db, 'user-posts/' + user.uid)
    onValue(postsRef, async (snapshot) => {
      const posts = Object.keys(await snapshot.val())

      for (let i = 0; i < posts.length; i++) {
        const userPostsImagesRef = dbRef(db, 'the-posts/' + posts[i])
        onValue(userPostsImagesRef, (snapshot) => {
          const content = snapshot.val()
          setPostObjects((postObjects) => [...postObjects, content])
        })
      }
    })
  }

  const getUserPosts2 = (pageNumber, postsPerPage) => {
    // Get unique user posts IDs
    setPostObjects([]);
    const postsRef = dbRef(db, 'user-posts/' + user.uid);
  
    onValue(postsRef, async (snapshot) => {
      const posts = Object.keys(await snapshot.val());
  
      // Use a counter to keep track of the number of fetched posts
      let counter = 0;
  
      for (let i = 0; i < posts.length; i++) {
        const userPostsImagesRef = dbRef(db, 'the-posts/' + posts[i]);
        onValue(userPostsImagesRef, (snapshot) => {
          const content = snapshot.val();
          setPostObjects((postObjects) => [...postObjects, content]);
  
          // Check if the counter has reached the desired number of posts (postsPerPage)
          counter++;
          if (counter === postsPerPage) {
            // Stop fetching more posts if we have reached the limit
            return;
          }
        });
      }
    });
  };
  //
  //
  //Get Community Posts
  const getCommunityPosts = (community) => {
    if (community[0] === 'Hentai') {
      return
    }
    setCommunityPosts([])
    let names = Object.entries(animeFeed).filter(
      (anime) => anime[0] === community[0]
    )
    names[0].shift()
    names = Object.keys(names[0][0])
    const comPostsRef = dbRef(db, 'the-posts/')
    onValue(comPostsRef, (snapshot) => {
      const data = Object.entries(snapshot.val())

      data.filter((post) => {
        for (let i = 0; i < names.length; i++) {
          if (post[0] === names[i]) {
            setCommunityPosts((communityPosts) => [
              ...communityPosts,
              post.pop(),
            ])
          }
        }
      })
    })
  }
  //
  //
  //
  const getFeedPosts = () => {
    setFeedPosts([])

    const feedRef = dbRef(db, 'the-posts/')
    const postsRef = dbRef(db, 'user-feed/' + user.uid)

    onValue(postsRef, async (snapshot) => {
      const feedList = await snapshot.val()

      onValue(feedRef, async (snapshot) => {
        const allPosts = await snapshot.val()
        const res = Object.entries(allPosts).filter((post) =>
          Object.keys(feedList).includes(post[0])
        )

        const newRes = res.map((post) => post[1])
        setFeedPosts(res.reverse().map((post) => post[1]))
      })
    })
  }

  const getFeedPosts2 = (pageNumber, postsPerPage) => {
    const feedRef = dbRef(db, 'the-posts/');
    const postsRef = dbRef(db, 'user-feed/' + user.uid);
  
    onValue(postsRef, async (snapshot) => {
      const feedList = await snapshot.val();
  
      onValue(feedRef, async (snapshot) => {
        const allPosts = await snapshot.val();
        const res = Object.entries(allPosts).filter((post) =>
          Object.keys(feedList).includes(post[0])
        );
  
        const newRes = res.map((post) => post[1]);
  
        // Calculate the start and end indexes for the posts to return based on pagination
        const startIndex = (pageNumber - 1) * postsPerPage;
        const endIndex = startIndex + postsPerPage;
        const paginatedPosts = newRes.reverse().slice(startIndex, endIndex);
  
        setFeedPosts(paginatedPosts);
      });
    });
  };
  //
  //
  //
  //Functions for changing profile and banner images
  //
  //List
  const listStorage = async (index) => {
    const pathRef = ref(storage, `user-profile-images/${user.uid}/`)
    const listRes = await listAll(pathRef)
    setImagePath(listRes.items[index].fullPath)
    return listRes.items[index].fullPath
  }
  //
  //
  //
  //Delete profile picture
  const deleteImage = () => {
    deleteObject(ref(storage, imagePath))
  }

  //
  //
  //Upload profile picture and save storage path
  const saveImage = async (select) => {
    const res = uploadBytes(
      ref(
        storage,
        `/user-profile-images/${user.uid}/${Date.now()}${imageForm[1].image}`
      ),
      imageForm[1].image
    ).then((snapshot) => {
      return snapshot.metadata.fullPath
    })

    //Download profile picture url from storage
    const download = async (path) => {
      const newUrl = await getDownloadURL(ref(storage, path)).then((url) => {
        return url
      })
      //Update User object to display new profile photo
      const updatePic = (url) => {
        if (select === 1) {
          update(dbRef(db, '/users/' + user.uid), {
            profileImageUrl: url,
          })
        }
        if (select === 2) {
          update(dbRef(db, '/users/' + user.uid), {
            bannerImageUrl: url,
          })
        }
      }
      updatePic(newUrl)
    }

    const printPath = async () => {
      const path = await res
      download(path)
    }
    printPath()
  }

  //
  //
  //
  //Get Anime Community from post title
  const getAnimeCommunity = () => {
    let comsArr = []
    const communitiesRef = dbRef(db, 'anime-communities/')
    onValue(communitiesRef, async (snapshot) => {
      const coms = await snapshot.val()
      setCommunities(coms)
      Object.entries(coms).map((com) => {
        comsArr.push([
          com[1].Community,
          com[1].About,
          com[1].CommunityID,
          com[1].Image,
        ])
      })

      const sortComs = (coms) => {
        return coms.sort()
      }

      setComsList(sortComs(comsArr))
    })
  }
  //
  //
  //
  const getAnimeFeedAndFollowers = () => {
    const animeFeedRef = dbRef(db, 'anime-feed/')
    onValue(animeFeedRef, (snapshot) => {
      setAnimeFeed(snapshot.val())
    })

    // let followersArr = []
    const followers = dbRef(db, 'anime-followers/')
    onValue(followers, async (snapshot) => {
      let follow = await snapshot.val()
      // setAnimeFollowers(Object.entries(snapshot.val()))
      setAnimeFollowers(Object.entries(follow, 'follow'))
    })
  }

  //Return a specific anime community
  const getCommunity = (sub, type) => {
    if (communities) {
      const entries = Object.entries(communities)

      const result = entries.filter((entry) => entry[1].Community === sub)
      // console.log(result[0][1], 'result')
      if (type === 2) {
        return result[0][1].Thumbnail
      } else {
        return result[0][1].Image
      }
    }
  }
  //
  //
  //
  //Get comment timestamps and covert them
  const convertCommentTS = () => {
    setCommentTSArray([])
    const Now = Timestamp.now()
    comments.map((comment) => {
      const dif = Now.seconds - comment.Date
      setCommentTSArray((commentTSArray) => [...commentTSArray, dif])
    })
  }
  //
  //
  //
  const getComFeedComments = (posts, index) => {
    const postIDs = posts.map((post) => post.PostID)
    getComments(postIDs, index)
  }
  //Get comments for a specific post
  const getComments = async (posts, index) => {
    // console.log(posts, 'posts in getComments')
    // console.log(index, 'index in getComments')
    setComments([])
    setCommenters([])
    let commentsArr = []
    let newRef = undefined

    if (Array.isArray(posts)) {
      newRef = posts
    } else {
      newRef = Object.keys(posts).reverse()
      // console.log(newRef, 'newRef in getComments')
    }
    const commentRef = dbRef(db, 'comments/' + newRef[0])
    onValue(commentRef, async (snapshot) => {
      commentsArr = []
      if (!snapshot.exists()) {
        // console.log('does not exist')
        return
      }
      const keys = Object.keys(await snapshot.val())

      const commentList = await snapshot.val()

      for (let i = 0; i < keys.length; i++) {
        let holder = commentList[keys[i]]
        commentsArr.push(holder)
      }
      setComments(commentsArr)
      // console.log(commentsArr, 'for users')
      let commenterArr = []
      for (let i = 0; i < commentsArr.length; i++) {
        let commenterRef = dbRef(db, 'users/' + commentsArr[i].User)
        onValue(commenterRef, (snapshot) => {
          const data = snapshot.val()
          commenterArr.push(data)
        })
      }
      setCommenters(commenterArr)
    })
  }

  const getMediaComments = (posts, index) => {
    const postIDs = mediaPosts.map((post) => post.MediaID);
    getComments2(postIDs);
  }
  
  const getComments2 = async (postIDs) => {
    setComments([]);
    setCommenters([]);
    
    const commentsArr = [];
    const commenterArr = [];
    
    for (const postID of postIDs) {
      const commentRef = dbRef(db, `media-comments/${postID}`);
      onValue(commentRef, (snapshot) => {
        if (!snapshot.exists()) {
          return;
        }
        const commentData = snapshot.val();
        
        // Loop through comments within the post
        for (const commentID in commentData) {
          if (commentData.hasOwnProperty(commentID)) {
            const comment = commentData[commentID];
            commentsArr.push(comment);
            
            // Retrieve commenters' data
            const commenterRef = dbRef(db, `users/${comment.User}`);
            onValue(commenterRef, (snapshot) => {
              const data = snapshot.val();
              commenterArr.push(data);
            });
          }
        }
        
        setComments(commentsArr);
        setCommenters(commenterArr);
      });
    }
  }

  //
  //Get post id from user-posts
  const getPostID = (uid, index) => {
    // console.log(uid, 'user id in getPostID')
    // console.log(index, 'index in getPostID')
    const userPostRef = dbRef(db, 'user-posts/' + uid)
    onValue(userPostRef, async (snapshot) => {
      const postArr = await snapshot.val()
      getComments(postArr, index)
    })
  }
  //
  //Check if Current User has Liked a post
  const checkLiked = (uid, post) => {
    // console.log(post, 'post check')
    onValue(dbRef(db, '/post-like/' + post), async (snapshot) => {
      const data = await snapshot.val();
  
      if (data) {
        // Ensure data is not null or undefined
        if (Object.keys(data).includes(uid)) {
          setLiked(true);
        } else {
          setLiked(false);
        }
      } else {
        setLiked(false);
      }
    });
  };

  const checkLiked2 = (uid, post) => {
    // console.log(post, 'post check')
    onValue(dbRef(db, '/media-like/' + post), async (snapshot) => {
      const data = await snapshot.val()
      if (data) {
        // Ensure data is not null or undefined
        if (Object.keys(data).includes(uid)) {
          setLiked(true);
        } else {
          setLiked(false);
        }
      } else {
        setLiked(false);
      }
    })
  }

  //
  //
  const getCommentIDs = (postID) => {
    onValue(dbRef(db, '/comments/' + postID), async (snapshot) => {
      const data = await snapshot.val()
      if (data) setCommentIDs(Object.keys(data))
    })
  }

  const getCommentIDs2 = (postID) => {
    onValue(dbRef(db, '/media-comments/' + postID), async (snapshot) => {
      const data = await snapshot.val()
      if (data) setCommentIDs(Object.keys(data))
    })
  }
  //
  //
  //
  const likePost = (post, numLikes, liked) => {
    // console.log(post, 'posts')

    // console.log(numLikes, 'numLikes')

    if (liked) {
      update(dbRef(db, '/the-posts/' + post), {
        Likes: numLikes - 1,
      })
      update(dbRef(db, '/post-like/' + post), {
        [user.uid]: null,
      })
    } else {
      update(dbRef(db, '/the-posts/' + post), {
        Likes: numLikes + 1,
      })
      update(dbRef(db, '/post-like/' + post), {
        [user.uid]: 1,
      })
    }
  }

  const likeMedia = (MediaID, numLikes, liked) => {
    if (liked) {
      update(dbRef(db, 'media-like/' + MediaID), {
        [user.uid]: null,
      })

      update(dbRef(db, 'the-media-posts/' + MediaID), {
        Likes: numLikes - 1,
      })
    } else {
      update(dbRef(db, 'media-like/' + MediaID), {
        [user.uid]: 1,
      })

      update(dbRef(db, 'the-media-posts/' + MediaID), {
        Likes: numLikes + 1,
      })
    }
  }
  //
  //
  const likeComment = (comment, index) => {
    // console.log(comment, 'comment')
    // console.log(index, 'index')
    // console.log(likedComments, 'liked comments')
    // console.log(likedComments[index], 'liked comments index')
    if (likedComments[index]) {
      update(
        dbRef(db, '/comments/' + `${comment.PostID}/` + comment.CommentID),
        {
          Likes: comment.Likes - 1,
        }
      )
      update(dbRef(db, '/comment-like/' + comment.CommentID), {
        [user.uid]: null,
      })
    } else {
      update(
        dbRef(db, '/comments/' + `${comment.PostID}/` + comment.CommentID),
        {
          Likes: comment.Likes + 1,
        }
      )
      update(dbRef(db, '/comment-like/' + comment.CommentID), {
        [user.uid]: 1,
      })
    }
  }

  const checkLikedComments = (uid, post) => {
    // console.log(post, 'post check')
    onValue(dbRef(db, '/comment-like/' + post), async (snapshot) => {
      const data = await snapshot.val()
      if (Object.keys(data).includes(uid)) {
        setLiked3(true)
      } else {
        setLiked3(false)
      }
    })
  }

  const checkLikedMediaComments = (uid, post) => {
    return new Promise((resolve, reject) => {
      onValue(dbRef(db, '/media-comment-like/' + post), async (snapshot) => {
        const data = await snapshot.val();
        if (data && Object.keys(data).includes(uid)) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, (error) => {
        reject(error);
      });
    });
  };

  const likeComment2 = (comment, index) => {
    // console.log(comment, 'comment')
    // console.log(index, 'index')
    // console.log(likedComments2, 'liked comments')
    // console.log(likedComments2[index], 'liked comments index')
    if (likedComments2[index]) {
      update(
        dbRef(db, '/media-comments/' + `${comment.PostID}/` + comment.CommentID),
        {
          Likes: comment.Likes - 1,
        }
      )
      update(dbRef(db, '/media-comment-like/' + comment.CommentID), {
        [user.uid]: null,
      })
    } else {
      update(
        dbRef(db, '/media-comments/' + `${comment.PostID}/` + comment.CommentID),
        {
          Likes: comment.Likes + 1,
        }
      )
      update(dbRef(db, '/media-comment-like/' + comment.CommentID), {
        [user.uid]: 1,
      })
    }
  }
  //
  //
  //
  const postComment = (newComment, postID, userID, sub, comments) => {
    const newCommentKey = push(child(dbRef(db), 'comments')).key

    update(dbRef(db, '/comments/' + `${postID}/` + newCommentKey), {
      Comment: newComment,
      CommentID: newCommentKey,
      Date: parseInt(Date.now() / 1000),
      Likes: 0,
      PostID: postID,
      Reply: 0,
      ReplyingID: 'None',
      ReplyingUser: 'None',
      Sub: sub,
      User: userID,
    })

    update(dbRef(db, '/the-posts/' + `${postID}/`), {
      Comments: comments + 1,
    })
  }

  const postComment2 = (newComment, postID, userID, sub, comments) => {
    const newCommentKey = push(child(dbRef(db), 'media-comments')).key

    update(dbRef(db, '/media-comments/' + `${postID}/` + newCommentKey), {
      Comment: newComment,
      CommentID: newCommentKey,
      Date: parseInt(Date.now() / 1000),
      Likes: 0,
      PostID: postID,
      Reply: 0,
      ReplyingID: 'None',
      ReplyingUser: 'None',
      Sub: sub,
      User: userID,
    })

    update(dbRef(db, '/the-media-posts/' + `${postID}/`), {
      Comments: comments + 1,
    })
  }
  //
  //
  const deleteComment = (postID, sub, CommentID) => {
    const newCommentKey = push(child(dbRef(db), 'comments')).key

    update(dbRef(db, '/comments/' + `${postID}/` + CommentID), {
      Comment: 'User deleted this comment :(',
      CommentID: CommentID,
      Date: parseInt(Date.now() / 1000),
      Likes: 0,
      PostID: postID,
      Reply: 0,
      ReplyingID: 'None',
      ReplyingUser: 'None',
      Sub: sub,
      User: 'AoJ31bgIMdMY5blzzwu6ypOSNXa2',
    })
  }
  //
  //
  const editComment = (postID, CommentID) => {
    update(dbRef(db, '/comments/' + `${postID}/` + CommentID), {
      Comment: editedComment,
    })
  }
  //
  //
  const postBio = (bio) => {
    update(dbRef(db, '/users/' + `${user.uid}`), {
      profileDescription: bio,
    })
  }
  //
  //
  const postBirthday = (birthday) => {
    update(dbRef(db, '/users/' + `${user.uid}`), {
      birthday: birthday,
    })
  }
  //
  //Test Promises
  const postToStorage = async () => {
    // console.log('Post to Storage Runs')
    let urlArr = []
    // let vidArr = []

    const vidRun = async () => {
      // console.log('vid Run Runs')
      const vid = await uploadBytes(
        ref(
          storage,
          `/post-videos/${user.uid}/${Date.now()}${imageForm[1].image}`
        ),
        imageForm[1].image
      ).then((snapshot) => {
        return snapshot.metadata.fullPath
      })

      // const generateThumbnail = async (vidUrl) => {
      //   console.log('Gen Thumb Runs')
      //   console.log(vidUrl, 'vidUrl')
      //   const video = videoRef.current
      //   const canvas = canvasRef.current

      //   // Set video source
      //   video.src = vidUrl

      //   // When video is loaded, capture a frame and draw it on the canvas
      //   video.onloadeddata = () => {
      //     canvas.width = video.videoWidth
      //     canvas.height = video.videoHeight
      //     canvas
      //       .getContext('2d')
      //       .drawImage(video, 0, 0, canvas.width, canvas.height)

      //     // Get the thumbnail as a base64 data URL
      //     const thumbnailUrl = canvas.toDataURL()
      //     console.log(thumbnailUrl)
      //   }
      // }

      const thumbnail = await uploadBytes(
        ref(
          storage,
          `/post-videos/${user.uid}/${Date.now()}${newPost.Thumbnail}`
        ),
        newPost.Thumbnail
      ).then((snapshot) => {
        return snapshot.metadata.fullPath
      })

      const assignVidURLs = async (url) => {
        // console.log('vidURLS RUNS')
        setNewPost({
          ...newPost,
          Image: url,
        })
        return true
      }

      const assignThumbnailURLs = async (url1, url2) => {
        // console.log('ThumbURLS RUNS')

        setNewPost({
          ...newPost,
          Image: url1,
          Thumbnail: url2,
        })
        return true
      }

      const download = async (path1, path2) => {
        const trig1 = await getDownloadURL(ref(storage, path1)).then((url) => {
          // return assignVidURLs(url)
          return url
        })
        const trig2 = await getDownloadURL(ref(storage, path2)).then((url) => {
          // return assignThumbnailURLs(url)
          return url
        })

        const trig7 = await assignThumbnailURLs(trig1, trig2)

        setVidsAssigned(trig7)
        setThumbnailAssigned(trig7)
        return trig7
      }
      const result = await download(vid, thumbnail)
      return result
    }

    const imageRun = async () => {
      for (let i = 0; i < imageForm.length - 1; i++) {
        // console.log('ran' + i)
        const img = await uploadBytes(
          ref(
            storage,
            `/post-images/${user.uid}/${Date.now()}${imageForm[i + 1].image}`
          ),
          imageForm[i + 1].image
        ).then((snapshot) => {
          return snapshot.metadata.fullPath
        })

        const assignImageURLs = async (URLs) => {
          switch (URLs.length) {
            case 1:
              setNewPost({ ...newPost, Image: URLs[0] })
              return i
            case 2:
              setNewPost({ ...newPost, Image: URLs[0], Image2: URLs[1] })
              return i
            case 3:
              setNewPost({
                ...newPost,
                Image: URLs[0],
                Image2: URLs[1],
                Image3: URLs[2],
              })
              return i
            case 4:
              setNewPost({
                ...newPost,
                Image: URLs[0],
                Image2: URLs[1],
                Image3: URLs[2],
                Image4: URLs[3],
              })
              return i
            case 5:
              setNewPost({
                ...newPost,
                Image: URLs[0],
                Image2: URLs[1],
                Image3: URLs[2],
                Image4: URLs[3],
                Image5: URLs[4],
              })
              return i
          }
        }

        const download = async (path) => {
          const trig1 = await getDownloadURL(ref(storage, path)).then((url) => {
            urlArr.push(url)
            return assignImageURLs(urlArr)
          })
          setImagesAssigned(trig1)
        }
        download(img)
      }
      return true
    }

    switch (newPost.Type) {
      case 0 || 5:
        break
      case 2:
        const trig3 = await vidRun()
        setTriggerPost(trig3)
        break
      case 1 || 4:
        const trig2 = await imageRun()
        setTriggerPost(trig2)
    }
  }

  //
  //
  //
  const resetNewPost = () => {
    setNewPost({
      ['Anime-Sub']: '--Community--',
      Comments: 0,
      Likes: 0,
      NSFW: 0,
      Spoiler: 0,
      Title: '',
      Type: '',
      User: user.uid,
    })
  }
  //
  //
  const makePost = async (newPost) => {
    // console.log(' mp ran')
    const newPostKey = push(child(dbRef(db), 'the-posts')).key
    // console.log(newPostKey, 'newPostKey')
    // URL is type 5
    // Video is type 2
    // Type 2 needs thumbnail
    // Media is type 1
    // Text is type 0
    // Gif is type 4
    // modify for Media and URL posts
    let testObj = {}
    testObj = { ...newPost, Date: Date.now(), PostID: newPostKey }
    // console.log(newPost, 'New')
    // console.log(testObj, 'Test')
    if (newPost.Type === 0) {
      update(dbRef(db, '/the-posts/' + newPostKey), {
        ['Anime-Sub']: newPost['Anime-Sub'],
        Body: newPost.Body,
        Comments: newPost.Comments,
        Date: Date.now(),
        Likes: newPost.Likes,
        NSFW: newPost.NSFW,
        PostID: newPostKey,
        Spoiler: newPost.Spoiler,
        Title: newPost.Title,
        Type: newPost.Type,
        User: newPost.User,
      })
    } else if (newPost.Type === 1 || newPost.Type === 4) {
      switch (imageForm.length) {
        case 2:
          update(dbRef(db, '/the-posts/' + newPostKey), {
            ['Anime-Sub']: newPost['Anime-Sub'],
            Comments: newPost.Comments,
            Date: Date.now(),
            Image: newPost.Image,
            ImageHeight: newPost.ImageHeight,
            ImageWidth: newPost.ImageWidth,
            Likes: newPost.Likes,
            NSFW: newPost.NSFW,
            PostID: newPostKey,
            Spoiler: newPost.Spoiler,
            Title: newPost.Title,
            Type: newPost.Type,
            User: newPost.User,
          })
          break
        case 3:
          update(dbRef(db, '/the-posts/' + newPostKey), {
            ['Anime-Sub']: newPost['Anime-Sub'],
            Comments: newPost.Comments,
            Date: Date.now(),
            Image: newPost.Image,
            ImageHeight: newPost.ImageHeight,
            ImageWidth: newPost.ImageWidth,
            Image2: newPost.Image2,
            ImageHeight2: newPost.ImageHeight2,
            ImageWidth2: newPost.ImageWidth2,
            Likes: newPost.Likes,
            NSFW: newPost.NSFW,
            PostID: newPostKey,
            Spoiler: newPost.Spoiler,
            Title: newPost.Title,
            Type: newPost.Type,
            User: newPost.User,
          })
          break
        case 4:
          update(dbRef(db, '/the-posts/' + newPostKey), {
            ['Anime-Sub']: newPost['Anime-Sub'],
            Comments: newPost.Comments,
            Date: Date.now(),
            Image: newPost.Image,
            ImageHeight: newPost.ImageHeight,
            ImageWidth: newPost.ImageWidth,
            Image2: newPost.Image2,
            ImageHeight2: newPost.ImageHeight2,
            ImageWidth2: newPost.ImageWidth2,
            Image3: newPost.Image3,
            ImageHeight3: newPost.ImageHeight3,
            ImageWidth3: newPost.ImageWidth3,
            Likes: newPost.Likes,
            NSFW: newPost.NSFW,
            PostID: newPostKey,
            Spoiler: newPost.Spoiler,
            Title: newPost.Title,
            Type: newPost.Type,
            User: newPost.User,
          })
          break
        case 5:
          update(dbRef(db, '/the-posts/' + newPostKey), {
            ['Anime-Sub']: newPost['Anime-Sub'],
            Comments: newPost.Comments,
            Date: Date.now(),
            Image: newPost.Image,
            ImageHeight: newPost.ImageHeight,
            ImageWidth: newPost.ImageWidth,
            Image2: newPost.Image2,
            ImageHeight2: newPost.ImageHeight2,
            ImageWidth2: newPost.ImageWidth2,
            Image3: newPost.Image3,
            ImageHeight3: newPost.ImageHeight3,
            ImageWidth3: newPost.ImageWidth3,
            Image4: newPost.Image4,
            ImageHeight4: newPost.ImageHeight4,
            ImageWidth4: newPost.ImageWidth4,
            Likes: newPost.Likes,
            NSFW: newPost.NSFW,
            PostID: newPostKey,
            Spoiler: newPost.Spoiler,
            Title: newPost.Title,
            Type: newPost.Type,
            User: newPost.User,
          })
          break
        case 6:
          update(dbRef(db, '/the-posts/' + newPostKey), {
            ['Anime-Sub']: newPost['Anime-Sub'],
            Comments: newPost.Comments,
            Date: Date.now(),
            Image: newPost.Image,
            ImageHeight: newPost.ImageHeight,
            ImageWidth: newPost.ImageWidth,
            Image2: newPost.Image2,
            ImageHeight2: newPost.ImageHeight2,
            ImageWidth2: newPost.ImageWidth2,
            Image3: newPost.Image3,
            ImageHeight3: newPost.ImageHeight3,
            ImageWidth3: newPost.ImageWidth3,
            Image4: newPost.Image4,
            ImageHeight4: newPost.ImageHeight4,
            ImageWidth4: newPost.ImageWidth4,
            Image5: newPost.Image5,
            ImageHeight5: newPost.ImageHeight5,
            ImageWidth5: newPost.ImageWidth5,
            Likes: newPost.Likes,
            NSFW: newPost.NSFW,
            PostID: newPostKey,
            Spoiler: newPost.Spoiler,
            Title: newPost.Title,
            Type: newPost.Type,
            User: newPost.User,
          })
          break
      }
    } else if (newPost.Type === 2) {
      update(dbRef(db, '/the-posts/' + newPostKey), {
        ['Anime-Sub']: newPost['Anime-Sub'],
        Comments: newPost.Comments,
        Date: Date.now(),
        Image: newPost.Image,
        ImageHeight: newPost.ImageHeight,
        ImageWidth: newPost.ImageWidth,
        Thumbnail: newPost.Thumbnail,
        Likes: newPost.Likes,
        NSFW: newPost.NSFW,
        PostID: newPostKey,
        Spoiler: newPost.Spoiler,
        Title: newPost.Title,
        Type: newPost.Type,
        User: newPost.User,
      })
    } else if (newPost.Type === 5) {
      //needs attention
      update(dbRef(db, '/the-posts/' + newPostKey), {
        ['Anime-Sub']: newPost['Anime-Sub'],
        Body: newPost.Body,
        Comments: newPost.Comments,
        Date: Date.now(),
        Image: newPost.Image,
        ImageHeight: newPost.ImageHeight,
        ImageWidth: newPost.ImageWidth,
        Likes: newPost.Likes,
        NSFW: newPost.NSFW,
        PostID: newPostKey,
        Spoiler: newPost.Spoiler,
        Title: newPost.Title,
        Type: newPost.Type,
        User: newPost.User,
      })
    }
    update(dbRef(db, '/user-posts/' + user.uid), {
      [newPostKey]: 1,
    })
    update(dbRef(db, '/anime-feed/' + newPost['Anime-Sub']), {
      [newPostKey]: 1,
    })
    resetNewPost()
    setPreview([])
  }

  const getMedia = () => {
    const mediaPostsRef = dbRef(db, 'the-media-posts/')
    const mediaKeywordsRef = dbRef(db, 'media-keywords/')
    const mediaLikesRef = dbRef(db, 'media-like/')
    const mediaPagesRef = dbRef(db, 'media-pages/')

    onValue(mediaPostsRef, async (snapshot) => {
      const posts = await snapshot.val()
      setMediaPosts(posts)
    })

    onValue(mediaKeywordsRef, async (snapshot) => {
      const keywords = await snapshot.val()
      setMediaKeywords(keywords)
    })

    onValue(mediaLikesRef, async (snapshot) => {
      const likes = await snapshot.val()
      setMediaLikes(likes)
    })

    onValue(mediaPagesRef, async (snapshot) => {
      const pages = await snapshot.val()
      setMediaPages(pages)
    })
  }

  const getEpisodes = async (baseMediaID, currentPage = 1, prevEpisodes = [], basePageFetched = false, isVideo = false) => {
    const mediaID = currentPage === 1 ? baseMediaID : `${baseMediaID}-${currentPage}`;
    const episodesRef = dbRef(db, 'media-pages/' + mediaID);
  
    const snapshot = await get(ref(episodesRef));
  
    if (snapshot.exists()) {
      if (!prevEpisodes.includes(mediaID)) {
        const data = snapshot.val();
        prevEpisodes.push(isVideo ? data[1] : data.slice(1));
  
        getEpisodes(baseMediaID, currentPage + 1, prevEpisodes, basePageFetched, isVideo);
      } else if (currentPage === 1 && basePageFetched) {
        // The base page doesn't exist, and we've already fetched it before
        // This means we have all the data, so you can stop fetching
      } else {
        const nextPage = currentPage === -1 ? 1 : currentPage + 1;
        getEpisodes(baseMediaID, nextPage, prevEpisodes, true, isVideo);
      }
    } else if (currentPage === 1) {
      getEpisodes(baseMediaID, -1, prevEpisodes, true, isVideo);
    } else {
      // No more pages found, you can stop fetching
      setEpisodes(prevEpisodes);
    }
  };

  function areEqual(prevData, newData) {
    // Check if the episode titles are the same
    return prevData.title === newData.title;
  }

  const followCommunity = (userID, community, communityPosts) => {
    // console.log(userID + ' followed.')
    // console.log(community)
    // console.log(communityPosts[0].PostID)

    update(dbRef(db, '/user-following/' + userID), {
      [community]: 1,
    })

    update(dbRef(db, '/anime-followers/' + community), {
      [userID]: 1,
    })

    //needs Testing
    for (let i = 0; i < communityPosts.length; i++) {
      update(dbRef(db, '/user-feed/' + userID), {
        [communityPosts[i].PostID]: 1,
      })
    }
  }
  //
  //
  const unfollowCommunity = (userID, community, communityPosts) => {
    // console.log(userID + ' followed.')
    // console.log(community)
    // console.log(communityPosts[0].PostID)

    update(dbRef(db, '/user-following/' + userID), {
      [community]: null,
    })

    update(dbRef(db, '/anime-followers/' + community), {
      [userID]: null,
    })

    for (let i = 0; i < communityPosts.length; i++) {
      update(dbRef(db, '/user-feed/' + userID), {
        [communityPosts[i].PostID]: null,
      })
    }
  }

  const getPostersInfo = (feed) => {
    for (let i = 0; i < feed.length; i++) {
      const posterRef = dbRef(db, 'users/' + feed[i].User)

      onValue(posterRef, async (snapshot) => {
        const exists = snapshot.exists()
        if (exists) {
          const data = await snapshot.val()
          setPostersList((postersList) => [...postersList, data])
        }
      })
    }
  }

  if (pending) {
    return <>Loading...</>
  }

  return (
    <UserContext.Provider
      value={{
        createUser,
        user,
        logout,
        login,
        email,
        password,
        setEmail,
        setPassword,
        passwordReset,
        emailVerification,
        userObj,
        following,
        getUserData,
        getUserFollowing,
        getUserPosts,
        getUserPosts2,
        postObjects,
        getPostLikes,
        likes,
        convertTS,
        checkNumPhotos,
        setNumPhotos,
        numPhotos,
        date,
        listStorage,
        deleteImage,
        saveImage,
        imagePath,
        setImageForm,
        setUploadButton,
        uploadButton,
        getCommunity,
        communities,
        getPostID,
        getComments,
        convertCommentTS,
        commentTSArray,
        comments,
        commenters,
        getCommentIDs,
        getCommentIDs2,
        checkLiked,
        checkLiked2,
        checkLikedComments,
        checkLikedMediaComments,
        likeComment,
        likeComment2,
        commentIDs,
        likedComments,
        likedComments2,
        setLikedComments,
        setLikedComments2,
        liked,
        liked2,
        liked3,
        liked4,
        likePost,
        likeMedia,
        postComment,
        postComment2,
        deleteComment,
        setCommentOption,
        commentOption,
        editComment,
        editedComment,
        setEditedComment,
        search,
        setSearch,
        animeFeed,
        comsList,
        filteredComs,
        setFilteredComs,
        community,
        setCommunity,
        animeFollowers,
        getCommunityPosts,
        communityPosts,
        setFeedPosts,
        feedPosts,
        getFeedPosts,
        getFeedPosts2,
        getMediaComments,
        getComFeedComments,
        hide,
        required,
        setRequired,
        postBio,
        postBirthday,
        makePost,
        setPreview,
        preview,
        getMedia,
        mediaPosts,
        mediaKeywords,
        mediaLikes,
        mediaPages,
        getEpisodes,
        setEpisodes,
        episodes,
        videoPath,
        setVideoPath,
        followCommunity,
        unfollowCommunity,
        follows,
        getPostersInfo,
        postersList,
        postToStorage,
        newPost,
        setNewPost,
        resetNewPost,
        userID,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const UserAuth = () => {
  return useContext(UserContext)
}
