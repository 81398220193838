import React from 'react'
import styles from './AllPostTypes.module.css'
import LikeIcon from '../LikeIcon/LikeIcon'
import UrlPreview2 from '../../Authenticate/Account/Navbar/Pages/CreatePost/UrlPreview/UrlPreview2'

function PostType5({ post, index }) {
  return (
    <div className={styles.Overlay}>
      <div className={styles.Post}>
      <UrlPreview2 url={post.theUrl} />
        {/* <img className={styles.Image} src={post.Image} alt="" /> */}
        <div className={styles.Likes__Comments}>
          <LikeIcon post={post} />
          {post.Likes}
          <img
            src={'/Rekishi_Icons/message@2x.png'}
            className={styles.Icons}
            id={styles.MessageIcon}
          />{' '}
          {post.Comments}
        </div>
      </div>
    </div>
  )
}

export default PostType5
