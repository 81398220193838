import React, { useState, useEffect } from 'react'
import styles from './Post.module.css'
import { ref as dbRef, onValue } from 'firebase/database'
import { db } from '../../../../../../firebase'
import { useParams } from 'react-router-dom'
import Comments from '../../../../../Reusable/PostModal/Comments/Comments'
import { UserAuth } from '../../../../../../Contexts/AuthContext'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'
import { Posts } from '../../../../../../Contexts/PostsContext'

function Post({ postIndex }) {
  const { theme, setIconTheme, iconTheme } = UserTheme()
  const {
    user,
    getCommunity,
    date,
    checkLiked,
    getCommentIDs,
    liked,
    likePost,
    postComment,
    numPhotos,
    checkNumPhotos,
    convertTS,
  } = UserAuth()

  const { getCommentData, clearCommentsData, comments, commenters } = Posts()

  const [post, setPost] = useState()
  const [newComment, setNewComment] = useState()
  const [postButton, setPostButton] = useState(true)
  const [photoNav, setPhotoNav] = useState(0)
  const [videoDisplay, setVideoDisplay] = useState(false)
  const { postid } = useParams()

  const getPost = (ID) => {
    onValue(dbRef(db, 'the-posts/' + ID), async (snapshot) => {
      const data = snapshot.val()
      // console.log(data, 'data')
      setPost(data)
    })
  }

  useEffect(() => {
    if (postid) getPost(postid)
  }, [postid])

  useEffect(() => {
    setIconTheme(theme)
  }, [theme])

  useEffect(() => {
    if (!newComment) {
      setPostButton(true)
    } else {
      setPostButton(false)
    }
  }, [newComment])

  useEffect(() => {
    if (postid) {
      getCommentIDs(postid)
      getCommentData(postid)
    }
  }, [postid])

  useEffect(() => {
    if (post) {
      checkLiked(user.uid, post.PostID)
      checkNumPhotos(post)
      convertTS(post.Date)
    }
  }, [post])

  if (!post) return null
  return (
    <>
      <div className={styles.Modal__Container}>
        <div className={styles.Modal}>
          <div className={styles.Title} data-theme={theme}>
            {post.Title}{' '}
          </div>
          <div className={styles.Menu} data-theme={theme}>
            {photoNav ? (
              <div
                className={styles.Nav}
                id={styles.Back}
                onClick={() => {
                  if (photoNav > 0) {
                    setPhotoNav(photoNav - 1)
                  }
                }}
              ></div>
            ) : (
              <div style={{ display: 'none' }}></div>
            )}

            {post.Type === 5 ? (
              <img
                src={getCommunity(post['Anime-Sub'])}
                alt="url"
                className={styles.Image}
                data-theme={theme}
              />
            ) : post.Type === 2 ? (
              !videoDisplay ? (
                <div className={styles.Content__Container}>
                  <img
                    src={post.Thumbnail}
                    alt="url"
                    className={styles.Image__Vid}
                  />
                  <div
                    className={styles.Play}
                    onClick={() => setVideoDisplay(true)}
                  ></div>
                </div>
              ) : (
                <video
                  controls
                  src={post.Image}
                  autoPlay={true}
                  type={'video/mp4'}
                  className={styles.Video}
                />
              )
            ) : post.Type === 0 ? (
              <div className={styles.Text}>{post.Body}</div>
            ) : post.Image2 ? (
              <img src={numPhotos[photoNav]} className={styles.Image} />
            ) : (
              <img src={post.Image} className={styles.Image} />
            )}
            {/* {numPhotos.length <= 1 ? (
              <div style={{ display: 'none' }}></div>
            ) : (
              <div
                className={styles.Nav}
                id={styles.Inner__Forward}
                // onClick={() => {
                //   if (photoNav < numPhotos.length - 1) {
                //     setPhotoNav(photoNav + 1)
                //   }
                // }}
              ></div>
            )} */}

            <div className={styles.Engage}>
              <div className={styles.Comment__Section} data-theme={theme}>
                {comments && commenters ? (
                  <Comments postIndex={postIndex} />
                ) : (
                  <div></div>
                )}
              </div>
              <div className={styles.Like__Display} data-theme={theme}>
                <button
                  className={`${styles['Like__Icon']} ${
                    styles[`${liked && 'liked'}`]
                  } ${styles[`${iconTheme}`]}`}
                  onClick={() => {
                    // likePost(posts, index, post.Likes)
                  }}
                ></button>
                <button
                  className={`${styles['Comment__Icon']} ${
                    styles[`${iconTheme}`]
                  }`}
                  onClick={() =>
                    console.log('')
                    // console.log(document.getElementById('commentField').focus())
                  }
                ></button>
                <div className={styles.Post__Date}>{date}</div>
                <div className={styles.Anime__Sub}>{post['Anime-Sub']}</div>
              </div>
              <div className={styles.Comment} data-theme={theme}>
                <div
                  className={`${styles['Comment__Icon']} ${
                    styles[`${iconTheme}`]
                  }`}
                  id={styles.Make__Comment__Icon}
                ></div>
                <input
                  className={styles.Comment__Input}
                  data-theme={theme}
                  id="commentField"
                  placeholder="Add a comment"
                  onChange={(e) => setNewComment(e.target.value)}
                />
                <button
                  className={styles.Post__Comment}
                  id={'post'}
                  disabled={postButton}
                  onClick={() => {
                    postComment(
                      newComment,
                      post.PostID,
                      user.uid,
                      post['Anime-Sub']
                    )
                  }}
                >
                  Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Post
