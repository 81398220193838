import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styles from './EditModal.module.css'
import { Modals } from '../../../../../../../../Contexts/ModalsContext'
import { UserAuth } from '../../../../../../../../Contexts/AuthContext'
import { UserTheme } from '../../../../../../../../Contexts/ThemeContext'

function EditModal({ postIndex }) {
  const {
    commentOption,
    postObjects,
    editComment,
    editedComment,
    setEditedComment,
  } = UserAuth()
  const { isEditOpen, setIsEditOpen, setIsOptionsOpen } = Modals()
  const { theme } = UserTheme()

  const [edit, setEdit] = useState(true)

  useEffect(() => {
    if (editedComment !== '') {
      setEdit(false)
    } else {
      setEdit(true)
    }
  }, [editedComment])

  if (!isEditOpen) return null

  return ReactDOM.createPortal(
    <>
      <div className={styles.Modal} data-theme={theme}>
        <div className={styles.Header}>
          <button
            className={styles.Cancel}
            onClick={() => setIsEditOpen(false)}
          >
            Cancel
          </button>
          <div className={styles.Edit__Comment}>Edit Comment</div>
          <button
            className={styles.Edit}
            disabled={edit}
            onClick={() => {
              editComment(
                postObjects[postIndex].PostID,
                commentOption.CommentID
              )
              setIsEditOpen(false)
              setIsOptionsOpen(false)
            }}
          >
            Edit
          </button>
        </div>
        <div className={styles.Comment}>
          <input
            className={styles.Input}
            autoFocus
            id="Input"
            type={'text'}
            defaultValue={editedComment}
            onChange={(e) => setEditedComment(e.target.value)}
          />
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default EditModal
