import React, { useEffect, useRef, useState } from 'react';
import styles from './LargeFeed.module.css';
import uuid from 'react-uuid';
import LikeIcon from '../../../../../../Reusable/LikeIcon/LikeIcon';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import { Timestamp } from 'firebase/firestore';
import { UserTheme } from '../../../../../../../Contexts/ThemeContext';
import { UserAuth } from '../../../../../../../Contexts/AuthContext';
import { Posts } from '../../../../../../../Contexts/PostsContext';

function LargeFeed({ post, index, postIDs }) {
  const { getPosts, setIsPostOpen, postRef } = Posts();
  const { theme } = UserTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const postsPerPage = 10;
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state
  const {
    setUserID,
    getCommunity,
    comsList,
    filteredComs,
    setFilteredComs,
    setCommunity,
    postersList,
  } = UserAuth();

  const videoRef = useRef(null);

  useEffect(() => {
    if (postIDs) getPosts(Object.keys(postIDs));
  }, [postIDs]);
  
    useEffect(() => {
    if (comsList) {
      setFilteredComs(comsList);
    }
  }, [comsList]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber]);

    useEffect(() => {
    if (isLoading) {
      return;
    }
  
    setIsLoading(true);

  }, [pageNumber]);

  const playPauseVid = (inView) => {
    if (inView && videoRef) {
      videoRef.current.play()
    } else if (videoRef === null) {
      return
    } else {
      videoRef.current.pause()
    }
  }

  const filterPostersInfo = (post) => {
    for (let i = 0; i < postersList.length; i++) {
      if (postersList[i].UserID === '-' + post.User) {
        return postersList[i].username
      }
    }
  }

  const findCom = (sub) => {
    // console.log(sub, 'sub')
    const result = filteredComs.filter((com) => com[0] === sub)
    // console.log(result[0], 'result')
    return result[0]
  }

  const convertFeedTS = (post) => {
    const Now = Timestamp.now()
    const dif = Now.seconds - post.Date

    switch (true) {
      //months
      case dif > 604800:
        return Math.round(dif / 604800) + ' Weeks Ago'
      //weeks
      case dif > 86400:
        return Math.round(dif / 86400) + ' Days Ago'
      //days
      case dif > 3600:
        return Math.round(dif / 3600) + ' Hours Ago'
      //minutes
      case dif > 60:
        return Math.round(dif / 60) + ' Minutes Ago'
      //seconds
      case dif < 60:
        return Math.round(dif) + ' Seconds Ago'
      default:
        return dif
    }
  }

  const {
    user,
    checkLiked
  } = UserAuth()

  return (
    <div className={styles.Flex__Item__Post} key={uuid()}>
      {post.Type === 5 && postersList.length > 0 ? (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Info}>
              <span className={styles.Title}>{post.Title}</span>
            </div>
            <div className={styles.Dots__Container}>
              <div className={styles.Dots}>...</div>
            </div>
          </div>
          <img
            src={getCommunity(post['Anime-Sub'])}
            alt={``}
            className={styles.Post__Image}
            onClick={() => {
              checkLiked(user.uid, post.postID);
              setIsPostOpen(true)
            }}
          ></img>
          <div className={styles.Footer}>
            <div className={styles.Subs__Poster}>
              <CustomLink
                className={styles.Com__Link}
                to={'/community/' + post.CommunityID}
                key={uuid()}
                onClick={() => setCommunity(findCom(post['Anime-Sub']))}
              >
                <span className={styles.Sub}>{post['Anime-Sub']}</span>
              </CustomLink>
              <span className={styles.Poster}>{filterPostersInfo(post)}</span>
            </div>
            <div className={styles.Icons__Time}>
              <div className={styles.Footer__Icons}>
                {' '}
                <div className={styles.Footer__Comments}>{post.Comments}</div>
                <img
                  src={'/Rekishi_Icons/message@2x2.png'}
                  className={styles.Icons}
                  id={styles.MessageIcon}
                  onClick={() => {
                    checkLiked(user.uid, post.postID);
                    setIsPostOpen(true)
                  }}
                />{' '}
                <div className={styles.Footer__Likes}>{post.Likes}</div>
                <LikeIcon post={post} />
              </div>
              <div className={styles.Time}>{convertFeedTS(post)}</div>
            </div>
          </div>
        </>
      ) : post.Type === 0 && postersList.length > 0 ? (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Info}>
              <span className={styles.Title}>{post.Title}</span>
            </div>
            <div className={styles.Dots__Container}>
              <div className={styles.Dots}>...</div>
            </div>
          </div>
          <div className={styles.Post__Text}>{post.Title}</div>
          <div className={styles.Footer}>
            <div className={styles.Subs__Poster}>
              <CustomLink
                className={styles.Com__Link}
                to={'/community/' + post.CommunityID}
                key={uuid()}
                onClick={() => setCommunity(findCom(post['Anime-Sub']))}
              >
                <span className={styles.Sub}>{post['Anime-Sub']}</span>
              </CustomLink>
              <span className={styles.Poster}>{filterPostersInfo(post)}</span>
            </div>
            <div className={styles.Icons__Time}>
              <div className={styles.Footer__Icons}>
                {' '}
                <div className={styles.Footer__Comments}>{post.Comments}</div>
                <img
                  src={'/Rekishi_Icons/message@2x2.png'}
                  className={styles.Icons}
                  id={styles.MessageIcon}
                  onClick={() => {
                    checkLiked(user.uid, post.postID);
                    setIsPostOpen(true)
                  }}
                />{' '}
                <div className={styles.Footer__Likes}>{post.Likes}</div>
                <LikeIcon post={post} />
              </div>
              <div className={styles.Time}>{convertFeedTS(post)}</div>
            </div>
          </div>
        </>
      ) : post.Type === 2 && postersList.length > 0 ? (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Info}>
              <span className={styles.Title}>{post.Title}</span>
            </div>
            <div className={styles.Dots__Container}>
              <div className={styles.Dots}>...</div>
            </div>
          </div>
          <InView
            as="div"
            threshold={0.7}
            onChange={(inView) => playPauseVid(inView)}
          >
            <video
              ref={videoRef}
              src={post.Image}
              alt={``}
              className={styles.Post__Image}
              controls
              loop
              muted
            ></video>
          </InView>
          <div className={styles.Footer}>
            <div className={styles.Subs__Poster}>
              <CustomLink
                className={styles.Com__Link}
                to={'/community/' + post.CommunityID}
                key={uuid()}
                onClick={() => setCommunity(findCom(post['Anime-Sub']))}
              >
                <span className={styles.Sub}>{post['Anime-Sub']}</span>
              </CustomLink>
              <span className={styles.Poster}>{filterPostersInfo(post)}</span>
            </div>
            <div className={styles.Icons__Time}>
              <div className={styles.Footer__Icons}>
                {' '}
                <div className={styles.Footer__Comments}>{post.Comments}</div>
                <img
                  src={'/Rekishi_Icons/message@2x2.png'}
                  className={styles.Icons}
                  id={styles.MessageIcon}
                  onClick={() => {
                    checkLiked(user.uid, post.postID);
                    setIsPostOpen(true)
                  }}
                />{' '}
                <div className={styles.Footer__Likes}>{post.Likes}</div>
                <LikeIcon post={post} />
              </div>
              <div className={styles.Time}>{convertFeedTS(post)}</div>
            </div>
          </div>
        </>
      ) : postersList.length > 0 ? (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Info}>
              <span className={styles.Title}>{post.Title}</span>
            </div>
            <div className={styles.Dots__Container}>
              <div className={styles.Dots}>...</div>
            </div>
          </div>
          <img
            src={post.Image}
            alt={``}
            className={styles.Post__Image}
            onClick={() => {
              checkLiked(user.uid, post.postID);
              setIsPostOpen(true)
            }}
          ></img>
          <div className={styles.Footer}>
            <div className={styles.Subs__Poster}>
              <CustomLink
                className={styles.Com__Link}
                to={'/community/' + post.CommunityID}
                key={uuid()}
                onClick={() => setCommunity(findCom(post['Anime-Sub']))}
              >
                <span className={styles.Sub}>{post['Anime-Sub']}</span>
              </CustomLink>
              <CustomLink
                className={styles.Poster__Link}
                to={'/user/' + post.User}
                key={uuid()}
                onClick={() => setUserID(post.User)}
              >
                <span className={styles.Poster}>{filterPostersInfo(post)}</span>
              </CustomLink>
            </div>
            <div className={styles.Icons__Time}>
              <div className={styles.Footer__Icons}>
                {' '}
                <div className={styles.Footer__Comments}>{post.Comments}</div>
                <img
                  src={'/Rekishi_Icons/message@2x2.png'}
                  className={styles.Icons}
                  id={styles.MessageIcon}
                  onClick={() => {
                    checkLiked(user.uid, post.postID);
                    setIsPostOpen(true)
                  }}
                />{' '}
                <div className={styles.Footer__Likes}>{post.Likes}</div>
                <LikeIcon post={post} />
              </div>
              <div className={styles.Time}>{convertFeedTS(post)}</div>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <Link to={to} {...props} id={isActive ? styles.active : ''}>
      {children}
    </Link>
  )
}

function handleScroll(isLoading, setIsLoading, setPageNumber, pageNumber) {
  if (
    window.innerHeight + window.scrollY >= document.body.offsetHeight &&
    !isLoading // Add a loading flag to prevent multiple requests
  ) {
    // Increment the page number to load the next page.
    setIsLoading(true); // Set isLoading to true to prevent additional requests
    setPageNumber(pageNumber + 1);
  }
}

export default LargeFeed
