import React, { useState, useEffect } from 'react'
import styles from './User.module.css'
import uuid from 'react-uuid'
import { db } from '../../../../../../../firebase'
import { ref as dbRef, onValue } from 'firebase/database'
import BackButton from '../../../../../../Reusable/BackButton/BackButton'
import PostType0 from '../../../../../../Reusable/PostTypes/PostType0'
import PostType1 from '../../../../../../Reusable/PostTypes/PostType1'
import PostType2 from '../../../../../../Reusable/PostTypes/PostType2'
import PostType5 from '../../../../../../Reusable/PostTypes/PostType5'
import Post from '../../Post/Post'
import PostModal from '../../../../../../Reusable/PostModal/PostModal'
import LoadingSpinner from '../../../../../../Reusable/LoadingSpinner/LoadingSpinner'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { UserAuth } from '../../../../../../../Contexts/AuthContext'
import { UserTheme } from '../../../../../../../Contexts/ThemeContext'
import { Posts } from '../../../../../../../Contexts/PostsContext'
import UrlPreview from '../../CreatePost/UrlPreview/UrlPreview'

function User() {
  const {
    getPosts,
    posts,
    getCommentData,
    isPostOpen,
    setIsPostOpen,
    postRef,
    prepPostUrl,
    revertUrl,
  } = Posts()
  const {
    user,
    checkNumPhotos,
    numPhotos,
    getCommentIDs,
    checkLiked,
    convertTS,
  } = UserAuth()
  const { theme } = UserTheme()
  const { userid } = useParams()
  const navigate = useNavigate()
  const [userView, setUserView] = useState()
  const [postIDs, setPostIDs] = useState()
  const [postIndex, setPostIndex] = useState()
  const [followers, setFollowers] = useState()
  const [following, setFollowing] = useState()

  useEffect(() => {
    const getUserData = (ID) => {
      onValue(dbRef(db, 'users/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setUserView(data)
      })
    }

    const getUserPostsFollowersFollowing = (ID) => {
      onValue(dbRef(db, 'user-posts/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setPostIDs(data)
      })

      onValue(dbRef(db, 'user-followers/' + ID), async (snapshot) => {
        if (snapshot.exists()) {
          const data = await snapshot.val()
          setFollowers(Object.values(data).length)
        }
      })

      onValue(dbRef(db, 'user-following/' + ID), async (snapshot) => {
        const data = await snapshot.val()
        setFollowing(Object.values(data).length)
      })
    }

    getUserData(userid)
    getUserPostsFollowersFollowing(userid)
  }, [])

  useEffect(() => {
    if (userView && user.uid === userid) {
      navigate('/profile')
    } else {
      if (postIDs) getPosts(Object.keys(postIDs))
    }
  }, [userView])

  useEffect(() => {
    if (isPostOpen) prepPostUrl(postRef.current.id)
    else revertUrl('user', userid)
  }, [isPostOpen])

  if (!userView) return <LoadingSpinner />

  return (
    <div className={styles.Wrapper} data-theme={theme}>
      <BackButton />
      <div className={styles.Banner__Container}>
        <img
          className={styles.BannerIMG}
          src={userView.bannerImageUrl}
          alt="banner"
        />
      </div>
      <div className={styles.Info__Container}>
        <div className={styles.Left__Info}>
          <img
            className={styles.ProfileIMG}
            src={userView.profileImageUrl}
            alt="profile"
          />
          <div className={styles.Name__Bio}>
            <div className={styles.Username}>{'@' + userView.username}</div>
            <div className={styles.Description}>
              {userView.profileDescription
                ? userView.profileDescription
                : 'No Description Yet'}
            </div>
          </div>
        </div>
        <div className={styles.Right__Info}>
          <div className={styles.Stats__Container}>
            <div className={styles.Stats__Item}>
              <div className={styles.Stat}>{Object.values(postIDs).length}</div>
              <div className={styles.Stat__Title}>Posts</div>
            </div>
            <div className={styles.Stats__Item}>
              <div className={styles.Stat}>{!followers ? 0 : followers}</div>
              <div className={styles.Stat__Title}>Followers</div>
            </div>
            <div className={styles.Stats__Item}>
              <div className={styles.Stat}>{following}</div>
              <div className={styles.Stat__Title}>Following</div>
            </div>
          </div>
          <button className={styles.FollowBTN}>Follow</button>
        </div>
      </div>
      <Post postIndex={postIndex} />
      <PostModal
        posts={posts}
        index={postIndex}
        setPostIndex={setPostIndex}
        checkNumPhotos={checkNumPhotos}
        numPhotos={numPhotos}
      />
      <div className={styles.Posts__Container}>
        {posts.reverse().map((post, index) =>
          !post ? (
            <div className={styles.null}></div>
          ) : post.Type === 1 ? (
            <Link
              ref={postRef}
              id={post.PostID}
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType1 post={post} index={index} />
            </Link>
          ) : post.Type === 2 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType2 post={post} index={index} />
            </Link>
          ) : post.Type === 0 ? (
            <Link
              key={uuid()}
              onClick={() => {
                setIsPostOpen(true)
                setPostIndex(index)
                getCommentIDs(post.PostID)
                checkLiked(user.uid, posts[index].PostID)
                checkNumPhotos(post)
                convertTS(post.Date)
                getCommentData(post.PostID)
              }}
            >
              <PostType0 post={post} index={index} />
            </Link>
          ) : post.Type === 5 ? (
            <>
              <Link
                key={uuid()}
                onClick={() => {
                  setIsPostOpen(true);
                  setPostIndex(index);
                  getCommentIDs(post.PostID);
                  checkLiked(user.uid, posts[index].PostID);
                  checkNumPhotos(post);
                  convertTS(post.Date);
                  getCommentData(post.PostID);
                }}
              >
                <PostType5 post={post} index={index} />
              </Link>
            </>
          ) : null
        )}
      </div>
    </div>
  )
}

export default User
