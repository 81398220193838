import React from 'react'
import '@stripe/stripe-js'
import './App.css'
import Login from './Components/Authenticate/Login/Login'
import SignUp from './Components/Authenticate/SignUp/SignUp'
import ForgotPassword from './Components/Authenticate/ForgotPassword/ForgotPassword'
import Account from './Components/Authenticate/Account/Account'
import Feed from './Components/Authenticate/Account/Navbar/Pages/Feed/Feed'
import User from './Components/Authenticate/Account/Navbar/Pages/Feed/User/User'
import Profile from './Components/Authenticate/Account/Navbar/Pages/Profile/Profile'
import Community from './Components/Authenticate/Account/Navbar/Pages/Search/Community/Community'
import MediaLanding from './Components/Authenticate/Account/Navbar/Pages/Media/MediaLandings/MediaLanding'
import Episodes from './Components/Authenticate/Account/Navbar/Pages/Media/MediaLandings/Episodes/Episodes'
import Media from './Components/Authenticate/Account/Navbar/Pages/Media/Media.jsx'
import Search from './Components/Authenticate/Account/Navbar/Pages/Search/Search.jsx'
import Shop from './Components/Authenticate/Account/Navbar/Pages/Shop/Shop.jsx'
import Success from './Components/Authenticate/Account/Navbar/Pages/Shop/Stripe/Success/Success'
import Cancel from './Components/Authenticate/Account/Navbar/Pages/Shop/Stripe/Cancel/Cancel'
import Item from './Components/Authenticate/Account/Navbar/Pages/Shop/ShopItems/Item/Item'
import Cart from './Components/Authenticate/Account/Navbar/Pages/Shop/ShopItems/Item/Cart/Cart.jsx'
import CreatePost from './Components/Authenticate/Account/Navbar/Pages/CreatePost/CreatePost.jsx'
import Post from './Components/Authenticate/Account/Navbar/Pages/Post/Post'
import YourAccount from './Components/Authenticate/Account/Sidebar/Pages/YourAccount/YourAccount.jsx'
import Content from './Components/Authenticate/Account/Sidebar/Pages/Content/Content.jsx'
import ADL from './Components/Authenticate/Account/Sidebar/Pages/AccessibilityDisplayLanguages/AccessibilityDipslayLanguages.jsx'
import RekishiPremium from './Components/Authenticate/Account/Sidebar/Pages/RekishiPremium/RekishiPremium.jsx'
import Purchases from './Components/Authenticate/Account/Sidebar/Pages/Purchases/Purchases.jsx'
import Security from './Components/Authenticate/Account/Sidebar/Pages/Security/Security.jsx'
import SafetyPrivacy from './Components/Authenticate/Account/Sidebar/Pages/SafetyPrivacy/SafetyPrivacy'
import PrivacyPolicy from './Components/Authenticate/Account/Sidebar/Pages/RekishiPremium/PrivacyPollicy/PrivacyPolicy'
import TermsAndConditions from './Components/Authenticate/Account/Sidebar/Pages/RekishiPremium/TermsAndConditions/TermsAndConditions'
import Return from './Components/Authenticate/Account/Sidebar/Pages/Purchases/Return/Return.jsx'
import { Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from './Contexts/AuthContext'
import { ThemeContextProvider } from './Contexts/ThemeContext'
import { ModalsContextProvider } from './Contexts/ModalsContext'
import { PostsContextProvider } from './Contexts/PostsContext'
import { CommunityContextProvider } from './Contexts/CommunityContext'
import { MediasContextProvider } from './Contexts/MediaContext'
import { CartContextProvider } from './Contexts/CartContext'
import { SearchProvider } from './Components/Authenticate/Account/Navbar/SearchProvider'
import { SearchProvider2 } from './Components/Authenticate/Account/Navbar/SearchProvider2'
import { SearchProvider3 } from './Components/Authenticate/Account/Navbar/SearchProvider3'
import ProtectedRoute from './Components/ProtectedRoute'

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <ThemeContextProvider>
          <ModalsContextProvider>
            <PostsContextProvider>
              <CommunityContextProvider>
                <MediasContextProvider>
                  <CartContextProvider>
                    <SearchProvider>
                      <SearchProvider2>
                        <SearchProvider3>
                          <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/signUp" element={<SignUp />} />
                            <Route
                              path="/forgotPassword"
                              element={<ForgotPassword />}
                            />{' '}
                            <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                            />
                            <Route path="/return" element={<Return />} />
                            <Route
                              path="terms-and-conditions"
                              element={<TermsAndConditions />}
                            />
                            <Route
                              path="/user-agreement"
                              element={<PrivacyPolicy />}
                            />
                            <Route
                              path="/"
                              element={
                                <ProtectedRoute>
                                  <Account />
                                </ProtectedRoute>
                              }
                            >
                              {/*Navbar Routes*/}
                              <Route path="feed" element={<Feed />} />
                              <Route path="user/:userid" element={<User />} />
                              <Route path="media" element={<Media />} />
                              <Route path="media/comments/:mediaid" element={<MediaLanding />} />
                              <Route
                                path="media/:mediaid"
                                element={<MediaLanding />}
                              />
                              <Route path="episodes" element={<Episodes />} />
                              <Route path="search" element={<Search />} />
                              <Route path="create-post" element={<CreatePost />} />
                              <Route path="post/:postid" element={<Post />} />
                              <Route path="shop" element={<Shop />} />
                              <Route path="item/:shoppostid" element={<Item />} />
                              <Route path="cart" element={<Cart />} />
                              <Route path="success" element={<Success />} />
                              <Route path="cancel" element={<Cancel />} />
                              <Route path="profile" element={<Profile />} />
                              <Route
                                path="community/:communityid"
                                element={<Community />}
                              />
                              {/*Sidebar Routes*/}
                              <Route path="your-account" element={<YourAccount />} />
                              <Route path="content" element={<Content />} />
                              <Route path="accessibility" element={<ADL />} />
                              {/*Rekishi Premium Routes*/}
                              <Route
                                path="rekishi-premium"
                                element={<RekishiPremium />}
                              />
                              <Route path="purchases" element={<Purchases />} />
                              <Route path="security" element={<Security />} />
                              <Route
                                path="safety-privacy"
                                element={<SafetyPrivacy />}
                              />
                            </Route>
                          </Routes>
                        </SearchProvider3>
                      </SearchProvider2>
                    </SearchProvider>
                  </CartContextProvider>
                </MediasContextProvider>
              </CommunityContextProvider>
            </PostsContextProvider>
          </ModalsContextProvider>
        </ThemeContextProvider>
      </AuthContextProvider>
    </div>
  )
}

export default App
