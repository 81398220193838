import React from 'react'
import styles from './Purchases.module.css'
import { UserTheme } from '../../../../../../Contexts/ThemeContext'

function Purchases() {
  const { theme } = UserTheme()

  return (
    <div className={styles.Container} data-theme={theme}>
      <p className={styles.Purchases}>Purchases</p>
      <div className={styles.Logo}></div>
      <p className={styles.Purchases__Message}>
        You have not bought an item from the Rekishi store. Go buy one!
      </p>
    </div>
  )
}

export default Purchases
