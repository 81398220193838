import React, { useState, useEffect } from 'react'
import styles from './Login.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../../../Contexts/AuthContext'
import { UserTheme } from '../../../Contexts/ThemeContext'

const Login = () => {
  const [loginButton, setLoginButton] = useState('disabled')
  const [error, setError] = useState('')

  const { user, login, hide, email, password, setEmail, setPassword } =
    UserAuth()
  const { theme, logo } = UserTheme()
  const navigate = useNavigate()

  useEffect(() => {
    if (email !== '' && password !== '') {
      setLoginButton('')
    } else {
      setLoginButton('disabled')
    }
  }, [email, password])

  useEffect(() => {
    // Check if the user is visiting on a mobile device
    const isMobileDevice = /(iPhone|iPad|iPod|Android)/i.test(
      navigator.userAgent
    )

    // Redirect to the App Store if it's a mobile device
    if (isMobileDevice) {
      window.location.href =
        'https://apps.apple.com/us/app/rekishi/id1593486431'
    }
  }, [])

  //Custom Error Messages
  const handleError = (e) => {
    switch (e.code) {
      case 'auth/wrong-password':
        setError('Sorry, that password is incorrect.')
        break
      case 'auth/invalid-email':
        setError(
          'Sorry, this is not a valid email. Please enter a valid email address.'
        )
        break
      case 'auth/unverified-email':
        setError('This email address in unverified')
        break
      case 'auth/user-not-found':
        setError('This account cannot be found.')
        break
      case 'auth/user-disabled':
        setError('This account has been disabled.')
        break
      case 'auth/internal-error':
        setError(
          "Sorry, we're having issues on our end, please try again after refreshing the page."
        )
        break
      case 'auth/network-request-failed':
        setError(
          'It seems you internet connection is unstable. Please try again after refreshing the page or checking your internet connection.'
        )
        break
    }
  }

  //Login Funciton
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      // console.log('login attempted')
      const check = await login(email, password)
      // console.log(check, 'check')
      navigate('/')
    } catch (e) {
      // console.log(e.code, 'e.code')
      handleError(e)
    } finally {
      navigate('/feed')
    }
  }

  if (hide)
    return (
      <div className={styles.Login__Wrapper} data-theme={theme}>
        <img
          src={logo}
          alt="logo"
          width="300"
          height="138"
          className={styles.Logo}
          data-theme={theme}
        />
      </div>
    )

  return (
    <div className={styles.Login__Wrapper} data-theme={theme}>
      <div>
        <img
          src={logo}
          alt="logo"
          width="300"
          height="138"
          className={styles.Logo}
          data-theme={theme}
        />
      </div>
      {error ? (
        <div className={styles.Error}>{error}</div>
      ) : (
        <div style={{ display: 'none' }}></div>
      )}
      <form onSubmit={handleSubmit}>
        <div className={styles.Login__Form__Inner}>
          <div className={styles.Login__Form__Group}>
            <label htmlFor="email"></label>
            <input
              className={styles.Login__Form__Inputs}
              type="email"
              name="email"
              id={styles.email}
              placeholder="Email"
              autoComplete="on"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={styles.Login__Form__Group}>
            <label htmlFor="password"></label>
            <input
              className={styles.Login__Form__Inputs}
              type="password"
              name="password"
              id={styles.password}
              placeholder="Password"
              autoComplete="on"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.Login__Form__Group}>
            <input
              className={styles.Login__Form__Inputs}
              type="submit"
              value="Login"
              id={styles.Login__Submit}
              disabled={loginButton}
            />
          </div>
          <p className={styles.Login__Form__Group}>
            <Link to="/ForgotPassword"> Forgot password?</Link>
          </p>
        </div>
      </form>
      <Link to="/SignUp">
        {' '}
        <p className={styles.SignUp__Link}>
          <span id={styles.Before__SignUp__Link}> Don't have an account? </span>{' '}
          &nbsp; Sign up
        </p>
      </Link>
    </div>
  )
}

export default Login
