import { createContext, useContext, useState } from 'react'
import { ref as dbRef, onValue, update } from 'firebase/database'
import { db } from '../firebase'

const CommunityContext = createContext()

export const CommunityContextProvider = ({ children }) => {
  /*1*/ const [community, setCommunity] = useState()

  const getCommunity = (communityID) => {
    onValue(dbRef(db, 'anime-communities/' + communityID), (snapshot) => {
      const data = snapshot.val()
      // console.log(data, 'data from Community Context')
      setCommunity(data)
    })
  }

  return (
    <CommunityContext.Provider
      value={{
        getCommunity,
        community,
      }}
    >
      {children}
    </CommunityContext.Provider>
  )
}

export const Communities = () => {
  return useContext(CommunityContext)
}
