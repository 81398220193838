import React from 'react'
import styles from './CartItem.module.css'
import { UserTheme } from '../../../../../../../../../../Contexts/ThemeContext'
import { UserCart } from '../../../../../../../../../../Contexts/CartContext'

function CartItem({ item, index }) {
  const { theme } = UserTheme()
  const { itemQuantities, addQuantity, reduceQuantity, handleRemoveItem } =
    UserCart()

  return (
    <div className={styles.Wrapper} data-theme={theme}>
      <img className={styles.Image} src={item.Image} />
      <div className={styles.Details}>
        <div className={styles.Name}>{item.Name}</div>
        <div className={styles.About}>
          <span>Item Description</span>&nbsp;
          {item.About}
        </div>
        <div className={styles.Seller}>{item.User}</div>
      </div>
      <div className={styles.Modify}>
        <div className={styles.Price}>${item.Price}</div>
        <div className={styles.Amount_Container}>
          <button
            className={styles.Amount_Buttons}
            id={styles.Decrement}
            onClick={() => reduceQuantity(item, index)}
          >
            -
          </button>
          <div className={styles.Quantity}>
            {itemQuantities[index][item.ShopPostID]}
          </div>
          <button
            className={styles.Amount_Buttons}
            id={styles.Increment}
            onClick={() => addQuantity(item, index)}
          >
            +
          </button>
        </div>
        <button
          className={styles.Remove}
          onClick={() => {
            handleRemoveItem(index)
          }}
        >
          Remove
        </button>
      </div>
    </div>
  )
}

export default CartItem
