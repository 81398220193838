import dateFormat from 'dateformat'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { UserAuth } from '../../../Contexts/AuthContext'
import { Medias } from '../../../Contexts/MediaContext'
import { UserTheme } from '../../../Contexts/ThemeContext'
import Comments from './Comments/Comments'
import styles from './MediaModal.module.css'

function MediaModal({ posts, index, numPhotos }) {
  const { theme, setIconTheme, iconTheme } = UserTheme()
  const [placeholder, setPlaceholder] = useState('Add a comment');
  const {
    user,
    getMediaComments,
    checkLiked2,
    getCommentIDs2,
    liked2,
    likeMedia,
    postComment2,
  } = UserAuth()

  const convertTS = (ts) => {
    const now = new Date(ts * 1000);
    const formattedDate = dateFormat(now, 'mmmm d yyyy');
    return formattedDate;
  }

  const date = convertTS(Date.now() / 1000);

  const {
    getCommentData,
    clearCommentsData,
    comments,
    commenters,
    isMediaOpen,
    setIsMediaOpen,
  } = Medias()

  const [newComment, setNewComment] = useState('')
  const [postButton, setPostButton] = useState(true)
  const [photoNav, setPhotoNav] = useState(0)
  const [videoDisplay, setVideoDisplay] = useState(false)

  useEffect(() => {
    setIconTheme(theme)
  }, [theme])

  useEffect(() => {
    if (!newComment) {
      setPostButton(true)
    } else {
      setPostButton(false)
    }
  }, [newComment])

  // useEffect(() => {
  //   Medias().getCommentData(posts[index].MediaID);
  // }, [index]);

  if (!isMediaOpen) return null
  return ReactDOM.createPortal(
    <>
      <div
        className={styles.Overlay}
        data-theme={theme}
        onClick={() => {
          setIsMediaOpen(false)
          setVideoDisplay(false)
          setPhotoNav(0)
          clearCommentsData()
        }}
      />
      {index !== 0 ? (
        <div
          className={styles.Nav__Outer}
          data-theme={theme}
          id={styles.Back}
          onClick={() => {
            if (index > 0) {
              getMediaComments(posts, index - 1)
              checkLiked2(user.uid, posts[index - 1].MediaID)
              getCommentIDs2(posts[index - 1].MediaID)
              setPhotoNav(0)
              getCommentData(posts[index - 1].MediaID)
            }
          }}
        ></div>
      ) : (
        <div style={{ display: 'none' }}></div>
      )}

      {index !== posts.length - 1 ? (
        <div
          className={styles.Nav__Outer}
          data-theme={theme}
          id={styles.Forward}
          onClick={() => {
            if (index < posts.length - 1) {
              getMediaComments(posts, index + 1)
              checkLiked2(user.uid, posts[index + 1].MediaID)
              getCommentIDs2(posts[index + 1].MediaID)
              setPhotoNav(0)
              getCommentData(posts[index + 1].MediaID)
            }
          }}
        ></div>
      ) : (
        <div style={{ display: 'none' }}></div>
      )}
      <div className={styles.Modal__Container}>
        <div className={styles.Modal}>
          <div className={styles.Title} data-theme={theme}>
            {posts[index].Title}{' '}
            <div
              className={styles.Close}
              onClick={() => {
                setIsMediaOpen(false)
                clearCommentsData()
              }}
            >
              <div className={styles.Bar}></div>
            </div>
          </div>
          <div className={styles.Menu} data-theme={theme}>
            {photoNav ? (
              <div
                className={styles.Nav}
                id={styles.Back}
                onClick={() => {
                  if (photoNav > 0) {
                    setPhotoNav(photoNav - 1)
                  }
                }}
              ></div>
            ) : (
              <div style={{ display: 'none' }}></div>
            )}

            <img src={posts[index].Image} className={styles.Image} />
            {numPhotos.length <= 1 ? (
              <div style={{ display: 'none' }}></div>
            ) : (
              <div
              ></div>
            )}

            <div className={styles.Engage}>
              <div className={styles.Comment__Section} data-theme={theme}>
                {comments && commenters ? (
                  <Comments postIndex={index} />
                ) : (
                  <div></div>
                )}
              </div>
              <div className={styles.Like__Display} data-theme={theme}>
                <button
                  className={`${styles['Like__Icon']} ${
                    styles[`${liked2 && 'liked'}`]
                  } ${styles[`${iconTheme}`]}`}
                  onClick={() => {
                    likeMedia(posts[index].MediaID, posts[index].Likes, liked2)
                  }}
                ></button>
                <button
                  className={`${styles['Comment__Icon']} ${
                    styles[`${iconTheme}`]
                  }`}
                  // onClick={() =>
                    // console.log(document.getElementById('commentField').focus())
                  // }
                ></button>
                <div className={styles.Post__Date}>{date}</div>
                <div className={styles.Anime__Sub}>
                  {posts[index]['Episodes' + (index + 1)]}
                </div>
              </div>
              <div className={styles.Comment} data-theme={theme}>
                <div
                  className={`${styles['Comment__Icon']} ${
                    styles[`${iconTheme}`]
                  }`}
                  id={styles.Make__Comment__Icon}
                ></div>
                <input
                  className={styles.Comment__Input}
                  data-theme={theme}
                  id="commentField"
                  placeholder="Add a comment"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                />
                <button
                  className={styles.Post__Comment}
                  id={'post'}
                  disabled={postButton}
                  onClick={() => {
                    // postComment2(
                    //   newComment,
                    //   posts[index].MediaID,
                    //   user.uid,
                    //   posts[index]['Title']
                    // );
                    setNewComment('');
                    // setPlaceholder("Add a comment");
                    Medias().getCommentData(posts[index].MediaID)
                  }}
                >
                  Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default MediaModal
